import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MixingFormulationWithMaterials, UploadedFile, WorkOrder } from '@twinsketch/topika-model';
import FormulationTable from "src/pages/workOrder/createNew/table/FormulationTable";
import { useFetchDataById } from "src/hook/CustomHook";
import ApiService from "src/services/ApiService";
import {
    FormulationToMixingHelper, translateToFromulationToMixingHelper
} from 'src/pages/workOrder/createNew//table/utils/helper';
import TankTable from "src/pages/workOrder/createNew/table/TankTable";
import ROUTES from "src/constants/routes";
import ErrorModal from "src/components/common/ErrorModal";
import DetailHeader from "src/components/detailPage/DetailHeader";
import { handleEdit } from "src/hook/CommonHandler";
import 'src/styles/workOrder/work-order.css'

const MinimumWorkOrderForm: React.FC<{}> = () => {

    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [selectedFormulation, setSelectedFormulation] = useState<MixingFormulationWithMaterials | null>(null);
    const [formulationHelper, setFormulationHelper] = useState<FormulationToMixingHelper | null>(null);
    const [draftMessage, setDraftMessage] = useState<string | null>(null);
    const [workOrder, setWorkOrder] = useState<WorkOrder | null>(null);

    useFetchDataById(ApiService.fetchWorkOrderById, id, setWorkOrder, setError);

    useEffect(() => {
        const fetchFormulation = async () => {
            if (workOrder?.formulationId) {
                try {
                    const formulation = await ApiService.fetchFormulationById(workOrder.formulationId);
                    const details = translateToFromulationToMixingHelper(formulation, workOrder.activeMaterialKg);
                    setSelectedFormulation(formulation);
                    setFormulationHelper(details);
                } catch (fetchError) {
                    console.error("Error fetching formulation:", fetchError);
                    setError("Failed to fetch formulation data.");
                }
            }
        };

        fetchFormulation();
    }, [workOrder]);

    useEffect(() => {
        if (workOrder?.files?.length && id) {
            const hasWorkOrderFile = workOrder.files.some((file: UploadedFile) => file.category === "WORK_ORDER");
            if (!hasWorkOrderFile) {
                setDraftMessage("This work order is draft. Please upload a valid work order file to make it ready.");
            }
        }
    }, [workOrder, id]);

    const handleWorkOrderDelete = async () => {
        if (!id) {
            setError("Work order ID is missing.");
            return;
        }
        try {
            await ApiService.deleteWorkOrderById(id);
            alert("Work order deleted successfully.");
            navigate(ROUTES.WORK_ORDERS);
        } catch (deleteError) {
            console.error("Failed to delete work order:", deleteError);
            setError("Failed to delete the work order. Please try again.");
        }
    };

    const updateWorkOrderFlag = async (isFlagged: boolean) => {
        if (!id) return;
        try {
            await ApiService.updateWorkOrder(id, { ...workOrder, flagged: isFlagged });
            setWorkOrder((prevWorkOrder) => ({
                ...prevWorkOrder!,
                flagged: isFlagged
            }));
        } catch (updateError) {
            console.error("Failed to update work order flag:", updateError);
            setError("Failed to update work order flag.");
        }
    };

    const handleEditWorkOrder = () => {
        if (id) {
            handleEdit(id, navigate, setError, ROUTES.WORK_ORDERS_EDIT(id));
        }
    };

    if (!workOrder) {
        return <div>Loading...</div>;
    }

    return (
        <div>

            <div className="content-wrapper">
                <ErrorModal message={error} onClose={() => setError(null)} />
                <div>
                    {draftMessage && <div className="error-message" style={{ color: "red", textAlign: "left", marginBottom: "1rem" }}>
                        {draftMessage}
                    </div>}
                </div>

                <div>
                    <div>
                        <div className="form-group">
                            <DetailHeader
                                title={`${workOrder.name || ''}`}
                                handleEdit={handleEditWorkOrder}
                                handleDelete={handleWorkOrderDelete}
                                createdAt={workOrder.createdAt}
                                createdBy={workOrder.createdBy}
                                updatedBy={workOrder.updatedBy}
                            />
                        </div>
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={workOrder.flagged || false}
                                    onChange={(e) => updateWorkOrderFlag(e.target.checked)}
                                />
                                Flag this work order
                            </label>
                        </div>
                        <div className="card">
                            <h2>Formulation and Mixer Tank Volume</h2>
                            <div className="form-group">
                                <label htmlFor="activeMaterialKg" style={{ display: 'block', marginBottom: '4px', fontWeight: 'bold' }}>
                                    Active Material (kg)
                                </label>

                                <div
                                    id="activeMaterialKg"
                                    style={{
                                        width: '200px',
                                        height: '30px',
                                        padding: '4px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        backgroundColor: '#f9f9f9',
                                        boxSizing: 'border-box',
                                        lineHeight: '22px'
                                    }}
                                >
                                    {workOrder.activeMaterialKg}
                                </div>

                            </div>
                            <div className="tables-wrapper">
                                <FormulationTable
                                    formulation={formulationHelper}
                                    setFormulation={setFormulationHelper}
                                    onFormulationUpdate={(updatedFormulation) => {
                                        setFormulationHelper(updatedFormulation);
                                    }} />
                                <div className="table-wrapper">
                                    <TankTable
                                        mixingTankVolumeFull={workOrder.mixingTankVolumeFull || 700}
                                        mixingTankVolumeWorking={workOrder.mixingTankVolumeWorking || 480}
                                        onUpdate={(updatedValues) =>
                                            setWorkOrder((prevWorkOrder) => ({
                                                ...prevWorkOrder,
                                                ...updatedValues,
                                            }))
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MinimumWorkOrderForm;
