import axios from 'axios';
import apiConfig from '../config/api';
import { MixingFormulation, MixingFormulationWithMaterials, PaginatedFormulationResponse, PaginatedWorkOrderResponse, WorkOrder } from '@twinsketch/topika-model';
import { MaterialDetails, MixingRecipe, MixingRecipeWithDetails } from '@twinsketch/topika-model';
import { Material, PaginatedMaterialResponse } from '@twinsketch/topika-model';
import { isTokenValid } from 'src/auth/tokenUtils';
import { CoatingRecipe, CoatingRecipeWithDetails } from '@twinsketch/topika-model';
import { prepareCoatingRecipeData, prepareMixingRecipeData } from 'src/services/DataPrep';
import { ActiveMaterialCategory, MaterialType } from '@twinsketch/topika-model';

class ApiService {
  static getToken = () => {
    const token = localStorage.getItem('authToken');
    if (!token) throw new Error('Session expired. Please log in again.');
    return token;
  };

  static handleRequest = async (method: 'get' | 'post' | 'put' | 'delete', url: string, data?: any) => {
    if (!isTokenValid()) throw new Error('Session expired. Please log in again.');

    const token = this.getToken();
    try {
      const response = await axios({
        method,
        url: `${apiConfig.API_BASE_URL}${url}`,
        data,
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        throw new Error('Session expired. Please log in again.');
      }
      console.error(`Error during ${method.toUpperCase()} request to ${url}:`, error);
      throw new Error(`Error during ${method.toUpperCase()} request`);
    }
  };

  // Mixing Recipe related API methods
  static calculateMixingDetails = async (
    recipeData: Omit<MixingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<{ binderMaterials: MaterialDetails[]; preMixMaterials: MaterialDetails[]; slurryMaterials: MaterialDetails[] }> => {
    return this.handleRequest('post', '/mixing-recipes/calculate-mixing', recipeData);
  };

  static fetchMixingRecipes = async (): Promise<MixingRecipeWithDetails[]> => {
    return this.handleRequest('get', '/mixing-recipes/all');
  };

  static fetchMixingRecipeById = async (id: string): Promise<MixingRecipeWithDetails> => {
    return this.handleRequest('get', `/mixing-recipes/${id}`);
  };

  static fetchMixingRecipesByFormulationId = async (formulationId: string): Promise<MixingRecipe[]> => {
    return this.handleRequest('get', `/mixing-recipes/formulation/${formulationId}`);
  };

  static createMixingRecipe = async (
    newRecipe: Omit<MixingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<MixingRecipe> => {
    return this.handleRequest('post', '/mixing-recipes', prepareMixingRecipeData(newRecipe));
  };

  static updateMixingRecipe = async (
    id: string,
    updatedRecipe: Omit<MixingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<MixingRecipe> => {
    return this.handleRequest('put', `/mixing-recipes/${id}`, prepareMixingRecipeData(updatedRecipe));
  };

  static deleteMixingRecipe = async (id: string): Promise<void> => {
    return this.handleRequest('delete', `/mixing-recipes/${id}`);
  };

  // Formulation related API methods
  static fetchFormulations = async (
    sortField: string = 'createdAt',
    sortDirection: 'asc' | 'desc' = 'asc',
    page?: number,
    pageSize?: number
  ): Promise<MixingFormulationWithMaterials[] | PaginatedFormulationResponse> => {
    const queryParams = new URLSearchParams({
      sortField,
      sortDirection,
    });

    if (page !== undefined && pageSize !== undefined) {
      queryParams.append('page', page.toString());
      queryParams.append('pageSize', pageSize.toString());
    }

    const response = await this.handleRequest('get', `/formulations/all?${queryParams.toString()}`);

    // If no pagination is requested, return only the formulations array.
    return page !== undefined && pageSize !== undefined ? response : response.data;
  };

  static fetchFormulationById = async (id: string): Promise<MixingFormulationWithMaterials> => {
    return this.handleRequest('get', `/formulations/${id}`);
  };

  static fetchFormulationsByMaterialId = async (materialId: string): Promise<MixingFormulation> => {
    return this.handleRequest('get', `/formulations/material/${materialId}`);
  };

  static createFormulation = async (
    newFormulation: Omit<MixingFormulation, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<MixingFormulation> => {
    return this.handleRequest('post', '/formulations', newFormulation);
  };

  static updateFormulation = async (
    id: string,
    updatedFormulation: Omit<MixingFormulation, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<MixingFormulation> => {
    return this.handleRequest('put', `/formulations/${id}`, updatedFormulation);
  };

  static deleteFormulation = async (id: string): Promise<void> => {
    return this.handleRequest('delete', `/formulations/${id}`);
  };


  // Material-related API methods
  static fetchMaterials = async (
    sortField: string = 'createdAt',
    sortDirection: 'asc' | 'desc' = 'asc',
    page?: number,
    pageSize?: number,
    materialType?: MaterialType | 'All',
    activeMaterialType?: ActiveMaterialCategory | 'All'
  ): Promise<Material[] | PaginatedMaterialResponse> => {
    const queryParams = new URLSearchParams({
      sortField,
      sortDirection,
    });

    if (page !== undefined && pageSize !== undefined) {
      queryParams.append('page', page.toString());
      queryParams.append('pageSize', pageSize.toString());
    }

    if (materialType && materialType !== 'All') {
      queryParams.append('materialType', materialType);
    }
    if (activeMaterialType && activeMaterialType !== 'All') {
      queryParams.append('activeMaterialType', activeMaterialType);
    }

    const response = await this.handleRequest('get', `/materials/all?${queryParams.toString()}`);

    // If no pagination is requested, return only the materials array.
    return page !== undefined && pageSize !== undefined ? response : response.data;
  };


  static fetchMaterialById = async (id: string, version?: string): Promise<Material> => {
    const queryParams = version ? `?version=${encodeURIComponent(version)}` : '';
    return this.handleRequest('get', `/materials/${id}${queryParams}`);
  };

  static createMaterial = async (
    newMaterial: Omit<Material, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<Material> => {
    return this.handleRequest('post', '/materials', newMaterial);
  };

  static updateMaterial = async (
    id: string,
    updatedMaterial: Omit<Material, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<Material> => {
    return this.handleRequest('put', `/materials/${id}`, updatedMaterial);
  };

  static deleteMaterial = async (id: string): Promise<void> => {
    return this.handleRequest('delete', `/materials/${id}`);
  };

  // Coating Recipe related API methods
  static createCoatingRecipe = async (
    newRecipe: Omit<CoatingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<CoatingRecipe> => {
    return this.handleRequest('post', '/coating-recipes', prepareCoatingRecipeData(newRecipe));
  };

  static fetchCoatingRecipes = async (): Promise<CoatingRecipeWithDetails[]> => {
    return this.handleRequest('get', '/coating-recipes/all');
  };

  static fetchCoatingRecipeById = async (id: string): Promise<CoatingRecipeWithDetails> => {
    return this.handleRequest('get', `/coating-recipes/${id}`);
  };

  static updateCoatingRecipe = async (
    id: string,
    updatedRecipe: Omit<CoatingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<CoatingRecipe> => {
    return this.handleRequest('put', `/coating-recipes/${id}`, prepareCoatingRecipeData(updatedRecipe));
  };

  static deleteCoatingRecipe = async (id: string): Promise<void> => {
    return this.handleRequest('delete', `/coating-recipes/${id}`);
  };

  // Work Order related API methods
  static createWorkOrder = async (
    newWorkOrder: Omit<WorkOrder, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>,
    isDraft: boolean
  ): Promise<WorkOrder> => {

    return this.handleRequest('post', '/work-orders', { ...newWorkOrder, draft: isDraft });
  };

  static updateWorkOrder = async (
    id: string,
    updatedWorkOrder: Omit<WorkOrder, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>
  ): Promise<WorkOrder> => {
    return this.handleRequest('put', `/work-orders/${id}`, updatedWorkOrder);
  };

  static fetchWorkOrderById = async (id: string): Promise<WorkOrder> => {
    return this.handleRequest('get', `/work-orders/${id}`);
  };

  static fetchWorkOrders = async (
    sortField: string = 'createdAt',
    sortDirection: 'asc' | 'desc' = 'asc',
    page?: number,
    pageSize?: number
  ): Promise<WorkOrder[] | PaginatedWorkOrderResponse> => {
    const queryParams = new URLSearchParams({
      sortField,
      sortDirection,
    });

    if (page !== undefined && pageSize !== undefined) {
      queryParams.append('page', page.toString());
      queryParams.append('pageSize', pageSize.toString());
    }

    const response = await this.handleRequest('get', `/work-orders/all?${queryParams.toString()}`);

    // If no pagination is requested, return only the workorder array.
    return page !== undefined && pageSize !== undefined ? response : response.data;
  };

  static fetchWorkOrdersByFormulationId = async (formulationId: string): Promise<WorkOrder[]> => {
    return this.handleRequest('get', `/work-orders/formulation/${formulationId}`);
  };

  static deleteWorkOrderById = async (id: string): Promise<void> => {
    return this.handleRequest('delete', `/work-orders/${id}`);
  };

}

export default ApiService;
