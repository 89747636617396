export interface InspectionDataResult {
    grindtest: number | null;
    viscosity: number | null;
    solidContentExpected: number | null;
    solidCntentActula: number | null;  // Keeping the original spelling
}

/**
 * Parses a 2D array for inspection data. Each cell is checked to see if it
 * matches a recognized keyword (e.g. "Grind Test", "점  도", etc.) and the
 * next numeric cell following that keyword is used to populate the result object.
 *
 * @param arr - A 2D array of strings/numbers representing inspection data
 * @returns An `InspectionDataResult` object containing parsed inspection values
 */
export function parseInspectionData(arr: (string | number)[][]): InspectionDataResult {
    // Prepare an object to store results
    const result: InspectionDataResult = {
        grindtest: null,
        viscosity: null,
        solidContentExpected: null,
        solidCntentActula: null
    };

    // A map of keywords -> function that sets the result.
    // Each function receives the numeric value found right after the keyword.
    const handlers: Record<string, (val: number) => void> = {
        "Grind Test": (val) => {
            result.grindtest = val;
        },
        "점  도": (val) => {
            result.viscosity = val;
        },
        "고형분": (val) => {
            result.solidContentExpected = val * 100; // e.g., convert 0.4174 -> 41.74
        },
        "고형분(실측)": (val) => {
            result.solidCntentActula = val * 100;    // e.g., convert 0.4186 -> 41.86
        }
    };

    // We'll keep track of the last recognized keyword so we know
    // which field the next numeric value belongs to.
    let lastKeyword: string | null = null;

    // Traverse the 2D array row-by-row, cell-by-cell
    for (const row of arr) {
        for (const cell of row) {
            // If we find a recognized keyword, remember it
            if (typeof cell === "string" && handlers[cell]) {
                lastKeyword = cell;
            }
            // If we find a number and we had a keyword waiting, call its handler
            else if (typeof cell === "number" && lastKeyword) {
                handlers[lastKeyword](cell);
                // Reset lastKeyword so we only capture the first numeric after each keyword
                lastKeyword = null;
            }
        }
    }

    return result;
}