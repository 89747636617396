export type ParsedConiditionData = {
    viscosity: number;
    temp: number;
    filteringMesh: string;
    vacuum: string;
};

/**
 * Extracts:
 *  viscosity (점 도) : 10500
 *  temp (온 도)      : 24
 *  filtering(mesh)   : "120 Mesh"
 *  vacuum (진공)     : "--0.08mpa"
 */
export function parseConditionData(data: (string | number | null)[][]): ParsedConiditionData {
    // For convenience, let's assume the data always has at least two rows
    // and the values we want exist at fixed indices:
    //  Row 1 (index 0): viscosity at index 5, temperature at index 21
    //  Row 2 (index 1): filtering mesh at index 5, vacuum at index 21

    const row1 = data[0] ?? [];
    const row2 = data[1] ?? [];

    // Viscosity (e.g., 10500)
    const viscosityRaw = row1[5];
    const viscosity = typeof viscosityRaw === "number"
        ? viscosityRaw
        : parseFloat(String(viscosityRaw ?? 0));

    // Temperature (e.g., "24℃" -> 24)
    const tempRaw = row1[21];
    const temp = parseFloat(String(tempRaw ?? "").replace(/[^\d.]/g, ""));

    // Filtering(mesh) (e.g., "120 Mesh")
    const filteringMesh = String(row2[5] ?? "").trim();

    // Vacuum (e.g., "ㅡ0.08MPA" -> "--0.08mpa")
    // Adjust the replacement logic as desired for your dash/format.
    const vacuumRaw = String(row2[21] ?? "").trim();
    const vacuum = vacuumRaw
        // Replace the leading Korean dash "ㅡ" with two ASCII dashes "--"
        .replace(/^ㅡ/, "--")
        // Convert "MPA" to lowercase "mpa" if desired
        .replace(/MPA$/i, "mpa");

    return {
        viscosity,
        temp,
        filteringMesh,
        vacuum,
    };
}
