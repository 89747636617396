export type ProcessData = {
    sequence: number,
    material: string | null,
    weight: number | null,
    mixingRpmLow: number,
    mixingRpmHigh: number,
    time: number,
    cps: number,
    note: string | null,
};

/**
 * Utility to safely parse a numeric cell (including handling "311.33+4.46" → 315.79).
 */
function parseWeightOrNumber(value: any): number | null {
    if (typeof value === "number") {
        return value;
    }
    if (typeof value === "string") {
        // Trim and check if it's empty
        const trimmed = value.trim();
        if (!trimmed) return null;

        // If there's a '+' sign, split and sum up
        if (trimmed.includes("+")) {
            const parts = trimmed.split("+").map((p) => parseFloat(p));
            // Filter out NaN and sum the rest
            const sum = parts.reduce((acc, cur) => (isNaN(cur) ? acc : acc + cur), 0);
            return isNaN(sum) ? null : sum;
        }

        // Otherwise, just parse as float
        const parsed = parseFloat(trimmed);
        return isNaN(parsed) ? null : parsed;
    }
    return null;
}

/**
 * Main function to parse the given 2D array into an array of ProcessData.
 */
export function extractProcessData(rawData: any[][]): ProcessData[] {
    // We will assume the first 3 rows are headers and skip them.
    // Adjust if your actual data has a different header structure.
    const dataStartIndex = 3;

    const processList: ProcessData[] = [];

    for (let i = dataStartIndex; i < rawData.length; i++) {
        const row = rawData[i];

        // For safety, handle cases where row might be shorter than expected
        const material = row[0] ?? null;
        const weight = parseWeightOrNumber(row[5]);
        const mixingRpmLow = parseWeightOrNumber(row[10]);
        const mixingRpmHigh = parseWeightOrNumber(row[14]);
        const time = parseWeightOrNumber(row[18]);
        const cps = parseWeightOrNumber(row[23]); // Column for 점도(cps)
        // The last column (row[25]) often looks like note
        const noteValue = row[25];
        // Convert to string if present
        const note = noteValue != null ? String(noteValue) : null;

        // Skip empty rows if needed – e.g., if there's no material name
        if (!material && weight === null && mixingRpmLow === null && mixingRpmHigh === null && time === null && cps === null && !note) {
            continue;
        }

        const processData: ProcessData = {
            sequence: i - dataStartIndex + 1, // e.g., 1-based indexing
            material,
            weight,
            mixingRpmLow,
            mixingRpmHigh,
            time,
            cps,
            note,
        };

        processList.push(processData);
    }

    return processList;
}

export default extractProcessData;