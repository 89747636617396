import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { UserProvider, useUser } from './auth/UserContext';
import ROUTES from './constants/routes';
import { isTokenValid } from './auth/tokenUtils';
import PrivateRoute from './auth/PrivateRoutes';

import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';

import WorkOrderHigherForm from './pages/workOrder/FormPage';
import WorkOrderForm from './pages/workOrder/createNew/WorkOrderForm';
import WorkOrderList from './pages/workOrder/ListPage';

import MaterialFormPage from './pages/material/FormPage';
import MaterialList from './pages/material/ListPage';
import MaterialDetail from './pages/material/DetailPage';

import FormulationList from './pages/formulation/ListPage';
import FormulationDetail from './pages/formulation/DetailPage';
import FormulationForm from './pages/formulation/FormPage';

import UserProfile from './pages/UserProfile';

import FloatingButton from './components/feedback/FloatingFeedback';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import 'src/styles/global.css';
import 'src/styles/header.css';
import 'src/styles/table-list.css';
import 'src/styles/main-page.css';
import 'src/styles/list-page.css';
import 'src/styles/new-detail.css';
import 'src/styles/utility.css';
import 'src/styles/error.css';
import MinimumWorkOrderForm from './pages/workOrder/form/MinimumWorkOrderForm';
import CreateEditForm from './pages/workOrder/form/CreateEditForm';

const App: React.FC = () => {
  const { user, setUser } = useUser();
  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <FloatingButton />
        <Routes>
          {/* Root route, redirects to either Main or Login based on token validity */}
          <Route
            path="/"
            element={
              isTokenValid() ? <Navigate to={ROUTES.MAIN} /> : <Navigate to={ROUTES.LOGIN} />
            }
          />

          <Route path={ROUTES.LOGIN} element={<LoginPage setUser={setUser} />} />

          {/* Group protected routes inside PrivateRoute */}
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Routes>
                  <Route path={ROUTES.MAIN} element={<MainPage />} />

                  <Route path={ROUTES.WORK_ORDERS} element={<WorkOrderList />} />
                  <Route path={ROUTES.WORK_ORDERS_CREATE} element={<WorkOrderHigherForm />} />
                  <Route path={ROUTES.WORK_ORDERS_DETAIL(':id')} element={<WorkOrderHigherForm />} />
                  <Route path={ROUTES.WORK_ORDERS_EDIT(':id')} element={<WorkOrderHigherForm />} />

                  <Route path={ROUTES.USER_PROFILE} element={<UserProfile />} />

                  <Route path={ROUTES.MATERIALS} element={<MaterialList />} />
                  <Route path={ROUTES.MATERIALS_CREATE} element={<MaterialFormPage />} />
                  <Route path={ROUTES.MATERIALS_DETAIL(':id')} element={<MaterialDetail />} />
                  <Route path={ROUTES.MATERIALS_EDIT(':id')} element={<MaterialFormPage />} />

                  <Route path={ROUTES.FORMULATIONS} element={<FormulationList />} />
                  <Route path={ROUTES.FORMULATIONS_CREATE} element={<FormulationForm />} />
                  <Route path={ROUTES.FORMULATIONS_DETAIL(':id')} element={<FormulationDetail />} />
                  <Route path={ROUTES.FORMULATIONS_EDIT(':id')} element={<FormulationForm />} />


                </Routes>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </I18nextProvider >
  );
};

const RootApp: React.FC = () => {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  );
};

export default RootApp;