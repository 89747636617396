import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { MixingFormulationWithMaterials, WorkOrder } from '@twinsketch/topika-model';
import ApiService from 'src/services/ApiService';
import { useFetchDataById } from 'src/hook/CustomHook';
import { handleDeleteEntity, handleEdit } from 'src/hook/CommonHandler';
import { MaterialType } from '@twinsketch/topika-model';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import DetailHeader from 'src/components/detailPage/DetailHeader';
import ErrorModal from 'src/components/common/ErrorModal';
import BackButton from 'src/components/common/BackButton';
import 'src/styles/formulation-detail.css';

const FormulationDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [formulation, setFormulation] = useState<MixingFormulationWithMaterials | null>(null);
  const [associatedWorkOrders, setAssociatedWorkOrders] = useState<WorkOrder[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch formulation details
  useFetchDataById(ApiService.fetchFormulationById, id, setFormulation, setError);

  // Fetch all recipes
  useEffect(() => {
    ApiService.fetchWorkOrdersByFormulationId(id!)
      .then(setAssociatedWorkOrders)
      .catch((err) => setError(err.message));
  }, []);

  const handleDeleteFormulation = () => {
    if (associatedWorkOrders.length > 0) {
      setError('Cannot delete formulation: It is still in use by work orders.');
      return;
    }
    handleDeleteEntity(ApiService.deleteFormulation, id!, ROUTES.FORMULATIONS, 'Error deleting formulation', navigate, setError);
  }

  const handleEditFormulation = () =>
    handleEdit(id!, navigate, setError, ROUTES.FORMULATIONS_EDIT(id!));

  if (!formulation) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <BackButton route={ROUTES.FORMULATIONS} />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="detail-page">
        <DetailHeader
          title={formulation.name}
          handleEdit={handleEditFormulation}
          handleDelete={null}
          createdAt={formulation.createdAt}
          updatedAt={formulation.updatedAt}
          createdBy={formulation.createdBy}
          updatedBy={formulation.updatedBy}
        />
        <div className="formulation-detail__body">
          <div className="formulation-detail__column">
            <div className="formulation-detail__section">
              <p>
                <strong>{t('formulationDataField.type')}:</strong>{' '}
                {t(`electrodeTypeEnum.${formulation.type}`)}
              </p>
              <p>
                <strong>{t('formulationDataField.locationTag')}:</strong>{' '}
                {formulation.locationTag || t('No location assigned')}
              </p>
            </div>

            {[MaterialType.ACTIVE_MATERIAL, MaterialType.CONDUCTING_AGENT, MaterialType.BINDER].map(
              (type) => {
                const elementsOfType = formulation.elements.filter(
                  (element) => element.material.materialType === type
                );
                if (elementsOfType.length === 0) return null;
                return (
                  <div key={type} className="formulation-detail__section">
                    <ul className="formulation-detail__material-list">
                      {elementsOfType.map((element) => (
                        <li key={element.materialId} className="formulation-detail__material-item">
                          <Link
                            to={ROUTES.MATERIALS_DETAIL(element.materialId)}
                            className="formulation-detail__material-link"
                          >
                            <div className="formulation-detail__material-name">
                              {element.material.materialName}
                            </div>
                          </Link>
                          <span className="formulation-detail__material-model">
                            {element.material.modelName}
                          </span>
                          <span className="formulation-detail__material-ratio">
                            {element.ratio}%
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              }
            )}

            <div className="formulation-detail__related-works formulation-detail__section">
              <h3 className="formulation-detail__section-title">{t('Related Work Orders')}</h3>
              {associatedWorkOrders.length > 0 ? (
                <ul className="formulation-detail__workorder-list">
                  {associatedWorkOrders.map((workOrder) => (
                    <li key={workOrder.id} className="formulation-detail__workorder-item">
                      <Link
                        to={ROUTES.WORK_ORDERS_DETAIL(workOrder.id)}
                        className="formulation-detail__material-link"
                      >
                        <span className="formulation-detail__workorder-name">{workOrder.name}</span>
                      </Link>

                    </li>
                  ))}
                </ul>
              ) : (
                <p>{t('No related work orders found.')}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormulationDetail;
