export interface CompositionData {
    category: string | null;
    ratio: number | null;
    name: string | null;
    weight: number | null;
}

export function parseComposition(
    data: (string | number | null)[][],
    // By default, start reading from column 5 (to skip the initial headers)
    startColIndex: number = 5
): CompositionData[] {
    /*
      Assuming row structure:
  
        Row 0: Title row (ignore)
        Row 1: Category names
        Row 2: Ratios
        Row 3: Material names
        Row 4: Weights
  
      Adjust indices if your layout differs.
    */

    const CATEGORY_ROW_INDEX = 1;
    const RATIO_ROW_INDEX = 2;
    const NAME_ROW_INDEX = 3;
    const WEIGHT_ROW_INDEX = 4;

    const categoryRow = data[CATEGORY_ROW_INDEX] ?? [];
    const ratioRow = data[RATIO_ROW_INDEX] ?? [];
    const nameRow = data[NAME_ROW_INDEX] ?? [];
    const weightRow = data[WEIGHT_ROW_INDEX] ?? [];

    const results: CompositionData[] = [];

    // Loop through columns, starting from (startColIndex) to the end
    for (let col = startColIndex; col < categoryRow.length; col++) {
        const categoryCell = categoryRow[col];

        // Skip columns that have no category text at all:
        if (typeof categoryCell !== "string" || !categoryCell.trim()) {
            continue;
        }

        // Get corresponding ratio, name, weight
        const ratioCell = ratioRow[col] ?? null;
        const nameCell = nameRow[col] ?? null;
        const weightCell = weightRow[col] ?? null;

        if (ratioCell != null && weightCell != null) {
            results.push({
                category: categoryCell.trim().replace(/\s+/g, ""),
                ratio: typeof ratioCell === "number" ? ratioCell : null,
                name: typeof nameCell === "string" ? nameCell.trim().replace(/\s+/g, "") : null,
                weight: typeof weightCell === "number" ? weightCell : null,
            });
        }
    }

    return results;
}
