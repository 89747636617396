import React, { useEffect, useState } from "react";
import Header from "src/components/common/Header";
import ErrorModal from "src/components/common/ErrorModal";
import Tabs from "src/pages/workOrder/form/CreateTab";
import { UploadedFile, WorkOrder } from "@twinsketch/topika-model";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApiService from "src/services/ApiService";
import { useFetchDataById } from "src/hook/CustomHook";
import FileService from "src/services/FileService";
import ExcelFileUploader from "./form/ExcelFileUploader";
import MinimumWorkOrderForm from "src/pages/workOrder/form/MinimumWorkOrderForm";
import RenderFileList from "./RenderFileList";
import CreateEditForm from "./form/CreateEditForm";
import "src/styles/workOrder/work-order.css";
import WorkOrderMixingWorkForm from "./form/WorkOrderMixingWork";

const WorkOrderHigherForm: React.FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const { id } = useParams<{ id?: string }>();
    const [isWorkOrderCreated, setIsWorkOrderCreated] = useState<boolean>(false);
    const [workOrderData, setWorkOrderData] = useState<WorkOrder | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const activeTab = searchParams.get('tab');
    const location = useLocation();
    const isEditMode = location.pathname.includes("/edit/");

    useEffect(() => {
        setIsWorkOrderCreated(!!id);
    }, [id]);

    useEffect(() => {
        if (id) {
            FileService.getFiles(id)
                .then((fetchedFiles) => setFiles(fetchedFiles))
                .catch((err) => setError(err.message));
        }
    }, [id]);

    useFetchDataById(ApiService.fetchWorkOrderById, id, setWorkOrderData, setError);

    // Define the base tabs
    const tabs = id
        ? [
            { key: 'thisWorkOrder', label: 'This Work Order', content: isEditMode ? <CreateEditForm /> : <MinimumWorkOrderForm /> },
            {
                key: 'uploadFiles', label: 'Upload Files', content: (
                    <ExcelFileUploader
                        workOrderId={id}
                        isWorkOrderCreated={isWorkOrderCreated}
                        workOrderData={workOrderData}
                        setWorkOrderData={setWorkOrderData}
                        setError={setError}
                    />
                )
            },
            {
                key: 'fileList', label: 'View Files', content: (
                    <RenderFileList
                        files={files}
                        workOrderId={id}
                        setError={setError}
                        workOrderData={workOrderData}
                        setWorkOrderData={setWorkOrderData}
                    />
                )
            }
        ]
        : [{ key: 'createWorkOrder', label: 'Create Work Order', content: <CreateEditForm /> }];

    // Conditionally add the "Mixing Work" tab if `mixingWork` exists and is not empty
    if (workOrderData?.mixingWork && workOrderData.mixingWork.length > 0) {
        tabs.push({
            key: 'mixingWork',
            label: 'Mixing Work',
            content: <WorkOrderMixingWorkForm files={files} mixingWorks={workOrderData.mixingWork} />
        });
    }

    const activeTabKey = tabs.find((tab) => tab.key === activeTab) ? activeTab : tabs[0]?.key || 'default';

    const handleTabChange = (key: string) => {
        setSearchParams({ tab: key });
    };

    return (
        <div>
            <Header />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <div className="content-wrapper">
                <Tabs tabs={tabs} activeKey={activeTabKey} onTabChange={handleTabChange} />
            </div>
        </div>
    );
};

export default WorkOrderHigherForm;
