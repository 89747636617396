import React, { useState } from "react";
import 'src/styles/workOrder/formulation-modal.css';
import { MixingFormulationWithMaterials } from "@twinsketch/topika-model";

interface FormulationModalProps {
    formulations: MixingFormulationWithMaterials[];
    onClose: () => void;
    onSelect: (selectedFormulation: MixingFormulationWithMaterials) => void;
}

const FormulationModal: React.FC<FormulationModalProps> = ({ formulations, onClose, onSelect }) => {
    const [searchTerm, setSearchTerm] = useState("");

    // Return null if there are no formulations to display
    if (!formulations || formulations.length === 0) {
        return null;
    }

    // Filter formulations based on the current searchTerm
    const filteredFormulations = formulations.filter((formulation) =>
        formulation.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="formulation-modal-overlay">
            <div className="formulation-modal-content">
                <button
                    className="formulation-modal-close-button"
                    onClick={onClose}
                >
                    Close
                </button>
                <h3>Select a Formulation</h3>
                <input
                    type="text"
                    placeholder="Search by formulation name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-bar"

                />
                <table className="formulation-modal-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Elements</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredFormulations.map((formulation) => (
                            <tr key={formulation.id}>
                                <td>{formulation.name}</td>
                                <td>{formulation.type}</td>
                                <td>
                                    {formulation.elements
                                        .map(
                                            (element) =>
                                                `${element.material.materialName} (${element.ratio}%, ${element.material.modelName})`
                                        )
                                        .join(", ")}
                                </td>
                                <td>
                                    <button
                                        className="formulation-modal-select-button"
                                        onClick={() => onSelect(formulation)}
                                    >
                                        Select
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {filteredFormulations.length === 0 && (
                            <tr>
                                <td colSpan={4}>No formulations found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FormulationModal;
