import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { Material } from '@twinsketch/topika-model';
import { ActiveMaterialCategory, MaterialType } from '@twinsketch/topika-model';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ListHeader from 'src/components/listPage/ListHeader';
import { useAppData } from 'src/components/AppDataContext';
import ErrorModal from 'src/components/common/ErrorModal';
import { MaterialFieldConfig, materialFieldsByType } from 'src/components/dataFields/MaterialTypeFields';
import SortButton, { CombinedSortableFields } from 'src/components/common/SortButton';
import Pagination from 'src/components/Pagination';
import SearchBar from 'src/components/listPage/SearchBar';
import ApiService from 'src/services/ApiService';

const MaterialsList: React.FC = () => {
  const { materials, setMaterials } = useAppData();
  const { t } = useTranslation();
  const location = useLocation();
  const [urlParams, setUrlParams] = useSearchParams();

  // State Management
  const [searchTerm, setSearchTerm] = useState(urlParams.get('q') ?? '');
  const [error, setError] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<MaterialType | 'All'>(location.state?.selectedTab || 'All');
  const [activeMaterialCategoryTab, setActiveMaterialCategoryTab] = useState<ActiveMaterialCategory | 'All'>(location.state?.activeMaterialCategoryTab || 'All');
  const [sortBy, setSortBy] = useState<CombinedSortableFields>('createdAt'); // Use CombinedSortableFields
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [nameSort, setNameSort] = useState<'asc' | 'desc' | null>(null);
  const currentPage = urlParams.get('p') ? parseInt(urlParams.get('p')) : 1;
  const pageSize = 20;
  const [totalPages, setTotalPages] = useState(Math.ceil(materials.length / pageSize));
  const [loading, setLoading] = useState(false);

  // Fetch Available Types
  const availableMaterialTypes = useMemo(() => Object.values(MaterialType).filter(type => type !== MaterialType.UNSPECIFIED) as MaterialType[], []);
  const availableActiveMaterialCategoryTypes = useMemo(() => Object.values(ActiveMaterialCategory).filter(type => type !== ActiveMaterialCategory.UNSPECIFIED) as ActiveMaterialCategory[], []);


  const handleTabChange = useCallback((tab: MaterialType | 'All') => setSelectedTab(tab), []);
  const handleActiveMaterialCategoryChange = useCallback((tab: ActiveMaterialCategory | 'All') => setActiveMaterialCategoryTab(tab), []);

  // Filter and Display Materials
  const filteredMaterials = useMemo(() => {
    return materials.filter(material =>
      Object.values(material)
        .filter(field => field && typeof field === 'string')
        .some(field => field.toLowerCase().includes(searchTerm))
    );
  }, [materials, searchTerm]);

  const materialsToDisplay = useMemo(() => {
    const materials = selectedTab === 'All' ? filteredMaterials
      : selectedTab === MaterialType.ACTIVE_MATERIAL && activeMaterialCategoryTab !== 'All'
        ? filteredMaterials.filter(
          material => material.materialType === selectedTab && material.activeMaterialCategory === activeMaterialCategoryTab)
        : filteredMaterials.filter(
          material => material.materialType === selectedTab);

    setTotalPages(Math.ceil(materials.length / pageSize));
    return materials.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [filteredMaterials, selectedTab, activeMaterialCategoryTab, currentPage]);

  const customFieldsForAllTab = useMemo(
    () => [
      { name: 'materialName', column: true },
      { name: 'modelName', column: true },
      { name: 'supplier', column: true },
      { name: 'createdBy', column: true },
      { name: 'updatedBy', column: true },
      { name: 'updatedAt', column: true },
      { name: 'locationTag', column: true },
    ],
    []
  );

  // Toggle sorting for materialName with 3 states: asc, desc, none
  const handleMaterialNameSort = () => {
    let nextSort: 'asc' | 'desc' | null;

    // Determine next sorting state
    if (nameSort === 'asc') {
      nextSort = 'desc';
    } else if (nameSort === 'desc') {
      nextSort = null;
    } else {
      nextSort = 'asc';
    }

    // Update state
    setNameSort(nextSort);

    // Determine the field to sort by (default to 'createdAt' when nameSort is null)
    const nextSortField = nextSort ? 'materialName' : 'createdAt';
    setSortBy(nextSortField);

    // Determine the direction (defaults to 'asc' when switching to 'createdAt')
    const nextSortDirection = nextSort || 'asc';
    setSortDirection(nextSortDirection);

    // Update URL parameters
    urlParams.set('p', '1');
    urlParams.set('sortField', nextSortField);
    urlParams.set('sortDirection', nextSortDirection);
    setUrlParams(urlParams);

    handleSortChange(nextSortField, nextSortDirection);
  };

  // Function to fetch formulations based on sorting
  const handleSortChange = async (sortField: CombinedSortableFields, sortDirection: 'asc' | 'desc') => {
    setLoading(true);
    try {
      const response = await ApiService.fetchMaterials(sortField, sortDirection, currentPage, pageSize);

      if ('data' in response && 'pagination' in response) {
        // It's a PaginatedFormulationResponse
        setMaterials(response.data); // Extract the `data` array
        setTotalPages(response.pagination.totalPages); // Update pagination state
      } else {
        // It's a simple array of MixingFormulationWithMaterials
        setMaterials(response);
        setTotalPages(Math.ceil(response.length / pageSize));
      }

      // Update URL params
      urlParams.set('sortField', sortField);
      urlParams.set('sortDirection', sortDirection);
      setUrlParams(urlParams);
    } catch (error) {
      console.error('Error fetching sorted materials:', error);
      setError('Failed to fetch materials');
    }
    setLoading(false);
  };

  const getTranslatedValue = (fieldName: string, fieldValue: any) => {
    switch (fieldName) {
      case 'materialType':
        return t(`materialTypeEnum.${fieldValue}`);
      case 'materialBaseType':
        return t(`MaterialBaseTypeEnum.${fieldValue}`);
      default:
        return fieldValue;
    }
  };

  const getHeaderTranslatedValue = (fieldName: string) => {
    if (['createdBy', 'updatedBy', 'createdAt', 'updatedAt'].includes(fieldName)) {
      return t(`dataCommonField.${fieldName}`);
    }
    return t(`materialDataField.${fieldName}`);
  };

  // Component to render tabs
  const TabButton = ({ label, isActive, onClick }: { label: string; isActive: boolean; onClick: () => void }) => (
    <button onClick={onClick} className={isActive ? 'active' : ''}>
      {label}
    </button>
  );

  // Render table headers based on selected tab
  const renderTableHeaders = () => {
    const fields = (selectedTab === 'All' ? customFieldsForAllTab : materialFieldsByType[selectedTab as MaterialType] || []) as MaterialFieldConfig[];

    return fields
      .filter((field) => field.column ?? false) // Default to `false` if `column` is undefined
      .map((field) => (
        <th key={field.name} onClick={field.name === 'materialName' ? handleMaterialNameSort : undefined}>
          {getHeaderTranslatedValue(field.name)}
          {field.name === 'materialName' && (
            nameSort === 'asc' ? ' ▲' : nameSort === 'desc' ? ' ▼' : ' •')}
        </th>
      ));
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const renderTableDataCells = (material: Material) => {
    const fields = (selectedTab === 'All'
      ? customFieldsForAllTab : materialFieldsByType[selectedTab as MaterialType] || []) as MaterialFieldConfig[];

    return fields
      .filter((field) => field.column ?? false) // Default to `false` if `column` is undefined
      .map((field) => {
        const fieldValue = material[field.name as keyof Material];

        // Check if fieldValue is an array (like UploadedFile[]) and ignore it if so
        if (Array.isArray(fieldValue)) {
          return null; // Skip rendering this field
        }

        // Format updatedAt field to YYYY-MM-DD and numeric values to 3 decimal places
        const formattedValue =
          field.name === 'updatedAt' && typeof fieldValue === 'string'
            ? formatDate(fieldValue)
            : typeof fieldValue === 'number'
              ? fieldValue.toFixed(3)
              : fieldValue;

        return (
          <td key={field.name}>
            {field.name === 'materialName' ? (
              <Link className="product-link" to={ROUTES.MATERIALS_DETAIL(material.id)} state={{ selectedTab, activeMaterialCategoryTab }}>
                {formattedValue as string | number | boolean}
              </Link>
            ) : (
              getTranslatedValue(field.name as keyof Material, formattedValue)
            )}
          </td>
        );
      });
  };

  return (
    <div>
      <Header />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <main className="list-main-content">
        <ListHeader
          createRoute={ROUTES.MATERIALS_CREATE}
          addButtonLabel="Add New"
        />
        <SearchBar
          placeholder="ex) pvdf"
          value={searchTerm}
          onChange={(e) => {
            const term = e.target.value.toLowerCase();
            setSearchTerm(term);
            urlParams.set('q', term);
            urlParams.set('p', '1');
            setUrlParams(urlParams);
          }}
        />
        <SortButton
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          onSortChange={handleSortChange}
        />
        <div>
          <div className="tabs">
            <TabButton label="All" isActive={selectedTab === 'All'} onClick={() => handleTabChange('All')} />
            {availableMaterialTypes.map(type => (
              <TabButton key={type} label={t(`materialTypeEnum.${type}`)}
                isActive={selectedTab === type}
                onClick={() => handleTabChange(type)} />
            ))}
          </div>
          {selectedTab === MaterialType.ACTIVE_MATERIAL && (
            <div className="tabs secondary-tabs">
              <TabButton label="All" isActive={activeMaterialCategoryTab === 'All'} onClick={() => handleActiveMaterialCategoryChange('All')} />
              {availableActiveMaterialCategoryTypes.map(category => (
                <TabButton key={category} label={t(`activeMaterialCategoryEnum.${category}`)} isActive={activeMaterialCategoryTab === category} onClick={() => handleActiveMaterialCategoryChange(category)} />
              ))}
            </div>
          )}

          <table className="list-table">
            <thead>{renderTableHeaders()}</thead>
            <tbody>
              {materialsToDisplay.map(material => (
                <tr key={material.id}>{renderTableDataCells(material)}</tr>
              ))}
            </tbody>
          </table>
          <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={(pageNum) => {
            urlParams.set('p', `${pageNum}`);
            setUrlParams(urlParams);
          }} />
        </div>
      </main>
    </div>
  );
};

export default MaterialsList;
