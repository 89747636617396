import React, { useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { MaterialType, MixingFormulationWithMaterials } from '@twinsketch/topika-model';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ListHeader from 'src/components/listPage/ListHeader';
import { useAppData } from 'src/components/AppDataContext';
import Pagination from 'src/components/Pagination';
import SearchBar from 'src/components/listPage/SearchBar';
import SortButton, { CombinedSortableFields } from 'src/components/common/SortButton';
import ApiService from 'src/services/ApiService';

const FormulationList: React.FC = () => {
  const { formulations, setFormulations } = useAppData();
  const { t } = useTranslation();
  const location = useLocation();
  const [urlParams, setUrlParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(urlParams.get('q') ?? '');
  const [error, setError] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<CombinedSortableFields>('createdAt'); 
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [nameSort, setNameSort] = useState<'asc' | 'desc' | null>(null);
  const currentPage = urlParams.get('p') ? parseInt(urlParams.get('p')) : 1;
  const pageSize = 20;
  const [totalPages, setTotalPages] = useState(Math.ceil(formulations.length / pageSize));
  const [loading, setLoading] = useState(false);

  // Filter and Display Materials
  const filteredFormulations = useMemo(() => {
    return formulations.filter(formulation =>
      Object.values(formulation)
        .filter(field => field && typeof field === 'string')
        .some(field => field.toLowerCase().includes(searchTerm))
    );
  }, [formulations, searchTerm]);

  const formulationsToDisplay = useMemo(() => {
    const formulations = filteredFormulations;
    setTotalPages(Math.ceil(formulations.length / pageSize));
    return formulations.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [filteredFormulations, currentPage]);

  // Toggle sorting for name with 3 states: asc, desc, none
  const handleFormulationNameSort = () => {
    let nextSort: 'asc' | 'desc' | null;

    // Determine next sorting state
    if (nameSort === 'asc') {
      nextSort = 'desc';
    } else if (nameSort === 'desc') {
      nextSort = null;
    } else {
      nextSort = 'asc';
    }

    // Update state
    setNameSort(nextSort);

    // Determine the field to sort by (default to 'createdAt' when nameSort is null)
    const nextSortField = nextSort ? 'name' : 'createdAt';
    setSortBy(nextSortField);

    // Determine the direction (defaults to 'asc' when switching to 'createdAt')
    const nextSortDirection = nextSort || 'asc';
    setSortDirection(nextSortDirection);

    // Update URL parameters
    urlParams.set('p', '1');
    urlParams.set('sortField', nextSortField);
    urlParams.set('sortDirection', nextSortDirection);
    setUrlParams(urlParams);

    handleSortChange(nextSortField, nextSortDirection);
  };

  // Function to fetch formulations based on sorting
  const handleSortChange = async (sortField: CombinedSortableFields, sortDirection: 'asc' | 'desc') => {
    setLoading(true);
    try {
      const response = await ApiService.fetchFormulations(sortField, sortDirection, currentPage, pageSize);

      if ('data' in response && 'pagination' in response) {
        // It's a PaginatedFormulationResponse
        setFormulations(response.data); // Extract the `data` array
        setTotalPages(response.pagination.totalPages); // Update pagination state
      } else {
        // It's a simple array of MixingFormulationWithMaterials
        setFormulations(response);
        setTotalPages(Math.ceil(response.length / pageSize));
      }

      // Update URL params
      urlParams.set('sortField', sortField);
      urlParams.set('sortDirection', sortDirection);
      setUrlParams(urlParams);
    } catch (error) {
      console.error('Error fetching sorted formulations:', error);
      setError('Failed to fetch formulations');
    }
    setLoading(false);
  };

  if (error) {
    return <div className="error"><p>{error}</p></div>;
  }

  const renderTableHeaders = () => {
    return (
      <tr>
        <th onClick={handleFormulationNameSort} style={{ cursor: 'pointer' }}>
          {t(`formulationDataField.name`)}
          {nameSort === 'asc' ? ' ▲' : nameSort === 'desc' ? ' ▼' : ' •'}
        </th>
        {[
          t(`materialTypeEnum.Active Material`),
          t(`materialTypeEnum.Conducting Agent`),
          t(`materialTypeEnum.Binder`),
          t(`materialTypeEnum.Additive`),
        ].map((type) => (
          <th key={type}>{type}</th>
        ))}
        <th>{t(`formulationDataField.locationTag`)}</th>
      </tr>
    );
  };

  const renderTableDataCells = (formulation: MixingFormulationWithMaterials) => {
    return (
      <>
        <td>
          <Link to={ROUTES.FORMULATIONS_DETAIL(formulation.id)}>{formulation.name}</Link>
        </td>
        {[MaterialType.ACTIVE_MATERIAL, MaterialType.CONDUCTING_AGENT, MaterialType.BINDER, MaterialType.ADDITIVE].map((type) => {
          const elementsOfType = formulation.elements.filter(
            (element) => element.material.materialType === type
          );
          const sumRatio = elementsOfType.reduce((acc, element) => acc + element.ratio, 0);
          return (
            <td key={type}>
              {elementsOfType.length > 0 ? (
                <div className="formulation-cell">
                  <div className="formulation-details">
                    {elementsOfType.map((element) => (
                      <div key={element.materialId}>
                        <strong>{element.material.materialName}</strong>: {parseFloat(element.ratio.toString()).toFixed(2)}%
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <span>-</span>
              )}
            </td>
          );
        })}
        <td>{formulation.locationTag || '-'}</td>
      </>
    );
  };

  return (
    <div>
      <Header />
      <main className="list-main-content">
        <ListHeader
          createRoute={ROUTES.FORMULATIONS_CREATE}
          addButtonLabel="Add New"
        />
        <SearchBar
          placeholder="ex) pvdf"
          value={searchTerm}
          onChange={(e) => {
            const term = e.target.value.toLowerCase();
            setSearchTerm(term);
            urlParams.set('q', term);
            urlParams.set('p', '1');
            setUrlParams(urlParams);
          }}
        />
        <SortButton
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          onSortChange={handleSortChange} // Pass API call function
        />
        <div>
          <table className='formulation-list-table'>
            <thead>{renderTableHeaders()}</thead>
            <tbody>
              {formulationsToDisplay.map((formulation) => (
                <tr key={formulation.id}>{renderTableDataCells(formulation)}</tr>
              ))}
            </tbody>
          </table>
          <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={(pageNum) => {
            urlParams.set('p', `${pageNum}`);
            setUrlParams(urlParams);
          }} />
        </div>
      </main>
    </div>
  );
};

export default FormulationList;
