import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import Header from 'src/components/common/Header';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ErrorModal from 'src/components/common/ErrorModal';
import { WorkOrder } from '@twinsketch/topika-model';
import ApiService from 'src/services/ApiService';
import ListHeader from 'src/components/listPage/ListHeader';
import { FaFile, FaFlag } from 'react-icons/fa';
import FileService from 'src/services/FileService';
import { useAppData } from 'src/components/AppDataContext';
import SortButton, { CombinedSortableFields } from 'src/components/common/SortButton';
import SearchBar from 'src/components/listPage/SearchBar';
import Pagination from 'src/components/Pagination';

const FileIndicator = ({ workOrderId, workOrderName, category, files, onDownload }) => {
    const hasFile = Array.isArray(files) && files.some(file => file.category === category);

    return hasFile ? (
        <FaFile
            title="File available"
            style={{ cursor: 'pointer' }}
            onClick={() => onDownload(workOrderId, workOrderName, category)}
        />
    ) : null;
};

const WorkOrderList: React.FC = () => {
    const { workOrders, setWorkOrders } = useAppData();
    const { t } = useTranslation();
    const location = useLocation();
    const [urlParams, setUrlParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState(urlParams.get('q') ?? '');
    const [error, setError] = useState<string | null>(null);
    const [sortBy, setSortBy] = useState<CombinedSortableFields>('createdAt');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [nameSort, setNameSort] = useState<'asc' | 'desc' | null>(null);
    const currentPage = urlParams.get('p') ? parseInt(urlParams.get('p')) : 1;
    const pageSize = 20;
    const [totalPages, setTotalPages] = useState(Math.ceil(workOrders.length / pageSize));
    const [loading, setLoading] = useState(false);

    // Filter and Display Materials
    const filteredWorkOrders = useMemo(() => {
        return workOrders.filter(workOrder =>
            Object.values(workOrder)
                .filter(field => field && typeof field === 'string')
                .some(field => field.toLowerCase().includes(searchTerm))
        );
    }, [workOrders, searchTerm]);

    const formulationsToDisplay = useMemo(() => {
        const formulations = filteredWorkOrders;
        setTotalPages(Math.ceil(formulations.length / pageSize));
        return formulations.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    }, [filteredWorkOrders, currentPage]);


    // Toggle sorting for name with 3 states: asc, desc, none
    const handleWorkOrderNameSort = () => {
        let nextSort: 'asc' | 'desc' | null;

        // Determine next sorting state
        if (nameSort === 'asc') {
            nextSort = 'desc';
        } else if (nameSort === 'desc') {
            nextSort = null;
        } else {
            nextSort = 'asc';
        }

        // Update state
        setNameSort(nextSort);

        // Determine the field to sort by (default to 'createdAt' when nameSort is null)
        const nextSortField = nextSort ? 'name' : 'createdAt';
        setSortBy(nextSortField);

        // Determine the direction (defaults to 'asc' when switching to 'createdAt')
        const nextSortDirection = nextSort || 'asc';
        setSortDirection(nextSortDirection);

        // Update URL parameters
        urlParams.set('p', '1');
        urlParams.set('sortField', nextSortField);
        urlParams.set('sortDirection', nextSortDirection);
        setUrlParams(urlParams);

        handleSortChange(nextSortField, nextSortDirection);
    };


    // Function to fetch formulations based on sorting
    const handleSortChange = async (sortField: CombinedSortableFields, sortDirection: 'asc' | 'desc') => {
        setLoading(true);
        try {
            const response = await ApiService.fetchWorkOrders(sortField, sortDirection, currentPage, pageSize);

            if ('data' in response && 'pagination' in response) {
                // It's a PaginatedFormulationResponse
                setWorkOrders(response.data); // Extract the `data` array
                setTotalPages(response.pagination.totalPages); // Update pagination state
            } else {
                // It's a simple array of MixingFormulationWithMaterials
                setWorkOrders(response);
                setTotalPages(Math.ceil(response.length / pageSize));
            }

            // Update URL params
            urlParams.set('sortField', sortField);
            urlParams.set('sortDirection', sortDirection);
            setUrlParams(urlParams);
        } catch (error) {
            console.error('Error fetching sorted formulations:', error);
            setError('Failed to fetch formulations');
        }
        setLoading(false);
    };

    if (error) {
        return <div className="error"><p>{error}</p></div>;
    }

    const handleFileDownload = async (workOrderId, workOrderName, category) => {
        try {
            // Fetch the list of files for the work order
            const files = await FileService.getFiles(workOrderId);
            const file = files.find(f => f.category === category);

            if (file && file.s3Url) {
                // Fetch the file data as a Blob
                const response = await fetch(file.s3Url);
                const blob = await response.blob();

                // Determine a file extension from the URL or blob type if needed
                const urlParts = file.s3Url.split('.');

                // Create a temporary anchor element to trigger download
                const link = document.createElement('a');
                const blobUrl = window.URL.createObjectURL(blob);
                link.href = blobUrl;
                // Set the custom filename combining work order name and category
                link.download = `${workOrderName}-${category}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            } else {
                alert('File not found');
            }
        } catch (error) {
            console.error('Error fetching or downloading file:', error);
            alert('An error occurred while fetching the file.');
        }
    };

    const fileCategories = [
        { key: 'WORK_ORDER', label: t('작업지시서') },
        { key: 'MIXING_REPORT', label: t('믹싱일보') },
        { key: 'COATING_REPORT', label: t('코팅일보') },
        { key: 'PRESS_REPORT', label: t('프레스') },
        { key: 'SLITTING_REPORT', label: t('슬리팅') }
    ];

    const renderTableHeaders = () => {
        return (
            <tr>
                <th className="flag-column">{t('!')}</th>
                <th onClick={handleWorkOrderNameSort} style={{ cursor: 'pointer' }}>
                    {t(`workOrderDataField.name`)}
                    {nameSort === 'asc' ? ' ▲' : nameSort === 'desc' ? ' ▼' : ' •'}
                </th>
                <th>{t('workOrderDataField.formulationName')}</th>
                <th>{t('workOrderDataField.activeMaterialKg')}</th>
                <th>{t('workOrderDataField.mixingTankVolumeFull')}</th>
                <th>{t('Work order')}</th>
                <th>{t('Mixing')}</th>
                <th>{t('Coating')}</th>
                <th>{t('Press')}</th>
                <th>{t('Slitting')}</th>
                <th>{t('Created By')}</th>
            </tr>
        );
    };

    const renderTableDataCells = (workOrder: WorkOrder) => {
        return (
            <>
                <td className="flag-column">
                    {workOrder.flagged ? <FaFlag style={{ color: 'red' }} /> : ''}
                </td>
                <td>
                    <Link to={ROUTES.WORK_ORDERS_DETAIL(workOrder.id)}>{workOrder.name || '-'}</Link>
                </td>
                <td>{workOrder.formulationName || '-'}</td>
                <td>{workOrder.activeMaterialKg || '-'}</td>
                <td>{workOrder.mixingTankVolumeFull || '-'}</td>
                {fileCategories.map(({ key }) => (
                    <td key={key}>
                        <FileIndicator
                            workOrderId={workOrder.id}
                            workOrderName={workOrder.name || 'Unknown'}
                            category={key}
                            files={workOrder.files}
                            onDownload={handleFileDownload}
                        />
                    </td>
                ))}
                <td>{workOrder.createdBy || '-'}</td>
            </>
        );
    };

    return (
        <div>
            <Header />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <main className="list-main-content">
                <ListHeader
                    createRoute={ROUTES.WORK_ORDERS_CREATE}
                    addButtonLabel="Add New"
                />
                <SearchBar
                    placeholder="ex) pvdf"
                    value={searchTerm}
                    onChange={(e) => {
                        const term = e.target.value.toLowerCase();
                        setSearchTerm(term);
                        urlParams.set('q', term);
                        urlParams.set('p', '1');
                        setUrlParams(urlParams);
                    }}
                />
                <SortButton
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    onSortChange={handleSortChange}
                />
                <div>
                    <table>
                        <thead>{renderTableHeaders()}</thead>
                        <tbody>
                            {formulationsToDisplay.map((workOrder) => (
                                <tr key={workOrder.id}>{renderTableDataCells(workOrder)}</tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={(pageNum) => {
                        urlParams.set('p', `${pageNum}`);
                        setUrlParams(urlParams);
                    }} />
                </div>
            </main>
        </div>
    );
};

export default WorkOrderList;
