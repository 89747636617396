import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MixingWork, UploadedFile } from "@twinsketch/topika-model";
import ErrorModal from "src/components/common/ErrorModal";
import 'src/styles/workOrder/work-order.css';

interface WorkOrderMixingWorkFormProps {
    mixingWorks: MixingWork[];
    files: UploadedFile[];
}

const WorkOrderMixingWorkForm: React.FC<WorkOrderMixingWorkFormProps> = ({ mixingWorks, files }) => {
    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);

    return (
        <div className="content-wrapper">
            <ErrorModal message={error} onClose={() => setError(null)} />

            <h2>Mixing Summary</h2>
            <div className="table-wrapper">
                <table className="mixing-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th>Worked On</th>
                            <th>Worked On</th>
                            <th>Tank Capacity (L)</th>
                            <th>Grind Test (㎛)</th>
                            <th>Viscosity (cps)</th>
                            <th>Calculated Solid Content (%)</th>
                            <th>Measured Solid Content (%)</th>
                            <th>Ref. Filename</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mixingWorks.length > 0 ? (
                            mixingWorks.map((work, index) => {
                                const matchedFile = work.originFileId
                                    ? files.find(file => file.id === work.originFileId)
                                    : null;

                                return (
                                    <tr key={index}>
                                        <td>{work.workedOn || "N/A"}</td>
                                        <td>{Array.isArray(work.workedBy) && work.workedBy.length > 0 ? work.workedBy.join(" | ") : "N/A"}</td>
                                        <td>{work.tankCapacity ?? "N/A"}</td>
                                        <td>{work.grindTestResult ?? "N/A"}</td>
                                        <td>{work.measuredViscosity ?? "N/A"}</td>
                                        <td>{work.calculatedSolidContent ?? "N/A"}</td>
                                        <td>{work.measuredsolidContent ?? "N/A"}</td>
                                        <td>
                                            {matchedFile ? (
                                                <span style={{ color: "green" }}>{matchedFile.fileName}</span>
                                            ) : (
                                                <span style={{ color: "gray" }}>No File Available</span>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={7} style={{ textAlign: "center", color: "gray" }}>
                                    No Mixing Work Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WorkOrderMixingWorkForm;
