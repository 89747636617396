import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MixingFormulationWithMaterials, WorkOrder } from "@twinsketch/topika-model";
import FormulationTable from "src/pages/workOrder/createNew/table/FormulationTable";
import FormulationModal from "src/pages/workOrder/createNew/FormulationModal";
import TankTable from "src/pages/workOrder/createNew/table/TankTable";
import ErrorModal from "src/components/common/ErrorModal";
import { useFetchData } from "src/hook/CustomHook";
import ApiService from "src/services/ApiService";
import { defaultRatiosByMaterialType, translateToFromulationToMixingHelper } from "src/pages/workOrder/createNew/table/utils/helper";
import ROUTES from "src/constants/routes";
import "src/styles/workOrder/work-order.css";

const initialWorkOrderState = {
    name: "",
    formulationId: "",
    formulationName: "",
    activeMaterialKg: 800,
    mixingTankVolumeFull: 700,
    mixingTankVolumeWorking: 490,
    coatingSpec: null,
    processYield: null,
    materialAmount: null,
    materialsNeeded: null,
    inspection: null,
    draft: false,
    flagged: false,
    files: [],
    mixingWork: []
};

const CreateEditForm: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id?: string }>();

    const [error, setError] = useState<string | null>(null);
    const [formulations, setFormulations] = useState<MixingFormulationWithMaterials[]>([]);
    const [selectedFormulation, setSelectedFormulation] = useState<MixingFormulationWithMaterials | null>(null);
    const [formulationHelper, setFormulationHelper] = useState({
        ratiosByMaterialType: defaultRatiosByMaterialType,
        activeMaterialKg: 800,
        formulation: null,
        elementsByMaterialType: null,
    });
    const [isFormulationSelectModalOpen, setIsFormulationSelectModalOpen] = useState(false);
    const [saveMessage, setSaveMessage] = useState<string | null>(null);
    const [workOrder, setWorkOrder] = useState<Omit<
        WorkOrder, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>>(initialWorkOrderState);

    useFetchData(ApiService.fetchFormulations, setFormulations, setError);

    useEffect(() => {
        const fetchData = async () => {
            if (!id) return;
            try {
                const data = await ApiService.fetchWorkOrderById(id);
                setWorkOrder({
                    ...data,
                    formulationName: data.formulationName || "",
                });

                const formulation = await ApiService.fetchFormulationById(data.formulationId);
                const details = translateToFromulationToMixingHelper(formulation, data.activeMaterialKg);

                setSelectedFormulation(formulation);
                setFormulationHelper(details);
            } catch (error) {
                setError("Failed to load work order data.");
            }
        };

        fetchData();
    }, [id, formulations]);

    const handleActiveMaterialKgChange = (value: number) => {
        setWorkOrder((prev) => ({ ...prev, activeMaterialKg: value }));
        setFormulationHelper((prev) => ({ ...prev, activeMaterialKg: value }));
    };

    const handleWorkOrderSave = async () => {
        if (!workOrder.name.trim() || !workOrder.formulationId) {
            setError("Work order name and formulation are required.");
            return;
        }

        try {
            let response;
            if (id) {
                // Update existing work order
                response = await ApiService.updateWorkOrder(id, workOrder);
            } else {
                response = await ApiService.createWorkOrder(workOrder, workOrder.draft);
            }
            const workOrderId = response?.id;
            if (!workOrderId) throw new Error("Draft ID not returned from API.");
            setSaveMessage(id ? "Work order updated!" : "Work order created!");
            setTimeout(() => {
                setSaveMessage(null);
                navigate(ROUTES.WORK_ORDERS_DETAIL(workOrderId));
            }, 900);
        } catch {
            setError("Failed to save work order. Please try again.");
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setWorkOrder((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
    };

    const renderInput = (label: string, name: string, type: string, value: string | number, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => (
        <div className="form-group">
            <label htmlFor={name} className="form-label">{label}</label>
            <input type={type} id={name} name={name} value={value} onChange={onChange} className="form-input" />
        </div>
    );

    return (
        <div className="content-wrapper">
            <ErrorModal message={error} onClose={() => setError(null)} />
            <div className="card">
                <h2>{id ? "Edit Work Order" : "Create Work Order"}</h2>
                {renderInput("Work Order Name", "name", "text", workOrder.name, handleInputChange)}

                <h2>Formulation and Mixer Tank Volume</h2>
                <button className="select-formulation-button" onClick={() => setIsFormulationSelectModalOpen(true)}>
                    {id ? "Change Formulation" : "Select Formulation"}
                </button>

                {renderInput("Active Material (kg)", "activeMaterialKg", "number", workOrder.activeMaterialKg, (e) => handleActiveMaterialKgChange(Number(e.target.value)))}

                <div className="tables-wrapper">
                    <FormulationTable
                        formulation={formulationHelper}
                        setFormulation={setFormulationHelper}
                        onFormulationUpdate={(updatedFormulation) => {
                            setFormulationHelper(updatedFormulation);
                        }}
                    />
                    <TankTable
                        mixingTankVolumeFull={workOrder.mixingTankVolumeFull}
                        mixingTankVolumeWorking={workOrder.mixingTankVolumeWorking}
                        onUpdate={(updatedValues) => setWorkOrder((prev) => ({ ...prev, ...updatedValues }))}
                    />
                </div>

                <div className="action-buttons-wrapper">
                    <button className="save-draft-button" onClick={handleWorkOrderSave}>
                        Save
                    </button>
                    {saveMessage && <span className="save-message">{saveMessage}</span>}
                </div>
            </div>

            {isFormulationSelectModalOpen && (
                <FormulationModal
                    formulations={formulations}
                    onClose={() => setIsFormulationSelectModalOpen(false)}
                    onSelect={(formulation) => {
                        setSelectedFormulation(formulation);
                        setWorkOrder((prev) => ({
                            ...prev,
                            formulationId: formulation.id,
                            formulationName: formulation.name,
                        }));
                        setFormulationHelper(translateToFromulationToMixingHelper(formulation, workOrder.activeMaterialKg));
                        setIsFormulationSelectModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default CreateEditForm;
