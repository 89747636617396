import * as XLSX from 'xlsx';

// Define types
export type DataCell = string | number | null;
export type DataRow = DataCell[];

export const checkWorkbookProtection = (workbook: XLSX.WorkBook) => {
    // Check if the workbook has sheets
    if (!workbook.SheetNames.length) {
        throw new Error("The uploaded file does not contain any sheets.");
    }

    // Get the first sheet
    const firstSheetName = workbook.SheetNames[0];
    const firstSheet = workbook.Sheets[firstSheetName];

    // Check if the first cell (A1) has protection-related messages
    const cellA1 = firstSheet["A1"];
    if (cellA1 && typeof cellA1.v === "string") {
        const cellValue = cellA1.v;

        // Detect protection message in the content
        if (cellValue.includes("protected") || cellValue.includes("ShadowCube Tech")) {
            throw new Error("This file is protected. Please remove the protection and upload again.");
        }
    }
};

/**
 * Placeholder implementations for undefined functions.
 * You need to replace these with your actual implementations.
 */
export function getSheetData(worksheet: XLSX.WorkSheet, range: XLSX.Range): DataRow[] {
    // Implement your logic to extract sheet data based on the range
    // For example, using XLSX.utils.sheet_to_json with range options
    const data: DataRow[] = XLSX.utils.sheet_to_json(worksheet, { header: 1, range });
    return data as DataRow[];
}

/**
   * Trims leading and trailing empty arrays from the input array.
   *
   * @param arr - The array to be trimmed.
   * @returns A new array without leading and trailing empty arrays.
   */
export function trimEmptyArrays<T>(arr: T[][]): T[][] {
    const trimmedArray = [...arr];

    // Trim leading empty arrays
    let start = 0;
    while (
        start < trimmedArray.length &&
        Array.isArray(trimmedArray[start]) &&
        trimmedArray[start].length === 0
    ) {
        start++;
    }

    // Trim trailing empty arrays
    let end = trimmedArray.length;
    while (
        end > start &&
        Array.isArray(trimmedArray[end - 1]) &&
        trimmedArray[end - 1].length === 0
    ) {
        end--;
    }

    return trimmedArray.slice(start, end);
}
