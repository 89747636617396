import { AmountYield, Inspection, ProcessYield } from '@twinsketch/topika-model';
import * as XLSX from 'xlsx';
import { checkWorkbookProtection, DataRow, getSheetData, trimEmptyArrays } from '../PreiviewUtils';

// Define the return type for better type safety
export interface WorkOrderPreviewData {
    processYieldData: ProcessYield;
    inspection: Inspection;
    noteText: string;
    materialsNeeded: AmountYield[];
    coatingSpecData: any[][];
    mixingTableData: any[][];
}

/**
 * Extracts specified columns from the data.
 *
 * @param data - The input data as an array of arrays.
 * @param cathodeKeywords - Possible headers for the first column (e.g., "Cathode", "Anode").
 * @param amountHeaders - Possible headers for the "Net amount" column.
 * @param quantityHeaders - Possible headers for the quantity column (e.g., "최소 필요량", "실투입량").
 * @returns The extracted data containing only the specified columns.
 */
function extractMaterialAmountColumns(
    data: DataRow[],
    cathodeKeywords: string[] = ["Cathode", "Anode", "Materials"],
    amountHeaders: string[] = ["Net amount"],
    quantityHeaders: string[] = ["최소 필요량", "실투입량"]
): DataRow[] {


    if (!data || data.length === 0 || data[0].length === 0) {
        return [];
    }

    const header = data[0];
    const headerMap: { [key: string]: number } = {};

    header.forEach((h, idx) => {
        if (h !== null) {
            headerMap[h] = idx;
        }
    });

    // Helper function to find the first matching header
    const findHeaderIndex = (options: string[]): number | null => {
        for (const option of options) {
            if (headerMap.hasOwnProperty(option)) {
                return headerMap[option];
            }
        }
        return null;
    };

    const cathodeIdx = findHeaderIndex(cathodeKeywords);
    const amountIdx = findHeaderIndex(amountHeaders);
    const quantityIdx = findHeaderIndex(quantityHeaders);

    if (cathodeIdx === null || amountIdx === null || quantityIdx === null) {
        throw new Error("One or more required columns are missing in the data.");
    }

    const extractedData: DataRow[] = [];

    // Add new header
    extractedData.push([header[cathodeIdx], header[amountIdx], header[quantityIdx]]);

    // Process each data row
    for (let i = 1; i < data.length; i++) {
        const row = data[i];
        if (!row || row.length === 0) {
            continue; // Skip empty rows
        }

        const cathode = cathodeIdx < row.length ? row[cathodeIdx] : null;
        const amount = amountIdx < row.length ? row[amountIdx] : null;
        const quantity = quantityIdx < row.length ? row[quantityIdx] : null;

        extractedData.push([cathode, amount, quantity]);
    }

    return extractedData;
}

function extractSectionDataWithIndices(data: DataRow[], startIndex: number, endIndex: number): DataRow[] {
    return data.slice(startIndex, endIndex);
}

export const findSectionStartIndex = (data: any[][], keyword: string): number => {
    return data.findIndex(
        row => row.some(cell => typeof cell === 'string' && cell.includes(keyword))
    );
};

function extractSectionDataWithIndicesno(
    data: DataRow[],
    startIndex: number,
    endIndex?: number
): DataRow[] {
    if (endIndex === undefined) {
        return data.slice(startIndex);
    }
    return data.slice(startIndex, endIndex);
}

export function toInspection(data: any[][]): Inspection {
    const binderSolidContent = data[1].slice(3).join(' ').trim();
    const binderViscosityRange = data[2].slice(3).join(' ').trim();
    const slurrySolidContent = data[3].slice(3).join(' ').trim();
    const slurryViscosityRange = data[4].slice(3).join(' ').trim();
    return {
        binderSolidContent,
        binderViscosityRange,
        slurrySolidContent,
        slurryViscosityRange,
    };
}

export const findCoatingWidthIndex = (data: any[][]): number => {
    return findSectionStartIndex(data, "Coating width");
};

export const toExtractNotes = (data: any[][], nextSectionStartIndex): string => {
    return data.slice(0, nextSectionStartIndex - 1)
        .map(row => row.filter(cell => cell != null).join(' '))
        .join('\n');
};

export function convertToMaterialAmount(data: any[][]): AmountYield[] {

    let amountYields: AmountYield[] = [];
    data.forEach((row, index) => {
        if (index === 0) return; // skip the header row
        if (!row[0]) return; // skip empty or undefined rows

        if (row?.[2] !== undefined && isNaN(parseFloat(row[2]))) {
            amountYields.push({
                materialName: String(row?.[0] ?? "N/A"),
                net: row?.[1] !== undefined && !isNaN(parseFloat(row[1])) ? parseFloat(row[1]) : 0,
                min: row?.[3] !== undefined && !isNaN(parseFloat(row[3])) ? parseFloat(row[3]) : 0,
            });
        } else {

            amountYields.push({
                materialName: String(row?.[0] ?? "N/A"),
                net: row?.[1] !== undefined && !isNaN(parseFloat(row[1])) ? parseFloat(row[1]) : 0,
                min: row?.[2] !== undefined && !isNaN(parseFloat(row[2])) ? parseFloat(row[2]) : 0,
            });
        }

    });
    return amountYields;
}

export function convertToProcessYield(data: any[][]): ProcessYield {
    // The rows containing Mix, Coat, Cal, Slit, Total yields
    // are assumed to follow the '% Yield' row in the exact order below.
    const mixRow = data[1]; // e.g., ["NMP", ..., "Mix", 0.95]
    const coatRow = data[2]; // e.g., ["코스모신소재 NCM523", ..., "Coat", 0.8]
    const calRow = data[3]; // e.g., ["도전재(불용자재 사용)", ..., "Cal", 0.95]
    const slitRow = data[4]; // e.g., ["Solvay 5130...", ..., "Slit", 0.98]
    const totalRow = data[5]; // e.g., [null, 0, "kg", 0, "kg", "Total", 0.70756]

    // Parse numeric yield values from the last column of each row
    const mixing = (parseFloat(mixRow?.[1]) || 0) * 100;
    const coating = (parseFloat(coatRow?.[1]) || 0) * 100;
    const calendering = (parseFloat(calRow?.[1]) || 0) * 100;
    const slitting = (parseFloat(slitRow?.[1]) || 0) * 100;
    const total = (parseFloat(totalRow?.[1]) || 0) * 100;

    return {
        mixing,
        coating,
        calendering,
        slitting,
        total,
        calculateTotalYield() {
            // Convert each percentage back to a decimal for the multiplication
            // then multiply the final product by 100 to get it as a percentage.
            //
            // e.g., if mixing = 95, coating = 80, calendering = 95, slitting = 98,
            // the calculation is:
            //   (95/100) * (80/100) * (95/100) * (98/100) * 100 ≈ 70.756
            const mixDec = this.mixing / 100;
            const coatDec = this.coating / 100;
            const calDec = this.calendering / 100;
            const slitDec = this.slitting / 100;
            return mixDec * coatDec * calDec * slitDec * 100;
        },
    };
}

function setError(message: string): void {
    // Implement your error handling logic
    // For example, throw an error or log it
    throw new Error(message);
}

export interface WorkOrderPreviewServiceInterface {
    getFilePreviewData(fileUrl: string): Promise<WorkOrderPreviewData>;
}

/**
 * Determines if a worksheet is a work order.
 * @param {string} sheetName - The name of the sheet.
 * @param {object} workbook - The workbook containing the sheet.
 * @returns {string | null} - The sheet name if it's identified as a work order, null otherwise.
 */
function getWorkOrderSheetName(sheetName, workbook) {
    const worksheet = workbook.Sheets[sheetName];
    if (!worksheet) {
        console.warn(`Worksheet not found for sheet name: ${sheetName}`);
        return null;
    }

    // Get relevant data from the worksheet
    const firstColumnSheet = getSheetData(worksheet, { s: { r: 0, c: 1 }, e: { r: 40, c: 9 } });

    // Determine if the worksheet contains sections for "Process" or "Inspection"
    const mixingTableStartIndex = findSectionStartIndexWithRegex(firstColumnSheet, "Process");
    const inspectionTableStartIndex = findSectionStartIndexWithRegex(firstColumnSheet, "Inspection");
    console.log("Sheet analysis", mixingTableStartIndex, inspectionTableStartIndex);

    if (mixingTableStartIndex !== -1 || inspectionTableStartIndex !== -1) {
        return sheetName;
    }

    return null;
}

export const findSectionStartIndexWithRegex = (data: any[][], keyword: string): number => {
    // Create a case-insensitive regex without word boundaries to be more flexible
    const regex = new RegExp(keyword, 'i');

    return data.findIndex(row =>
        row.some(cell => {
            if (typeof cell === 'string') {
                // Normalize the string by removing special characters and trimming whitespace
                const normalizedCell = cell.replace(/[\W_]+/g, ' ').trim();
                return regex.test(normalizedCell);
            }
            return false;
        })
    );
};


function getCellsBeforeKeyword(cells: DataRow, keyword: string): DataRow {
    // Create a regex with word boundaries for case-insensitive matching
    const keywordRegex = new RegExp(`\\b${keyword}\\b`, 'i');

    // Find the index of the first cell that contains the keyword
    const keywordIndex = cells.findIndex(cell => {
        // Ensure the cell is a non-null string before testing with regex
        return typeof cell === 'string' && keywordRegex.test(cell);
    });

    // If no such cell is found, return all cells; otherwise, return cells up to the found index
    return keywordIndex === -1 ? cells : cells.slice(0, keywordIndex);
}

function trimColumnsFromFront(matrix: (string | number | null)[][], x: number): (string | number | null)[][] {
    return matrix.map(row => row.slice(x));
}

function buildMixingTable(firstColumnSheet: DataRow[]) {
    const mixingTableStartIndex = findSectionStartIndexWithRegex(firstColumnSheet, "process");
    const mixingTableEndIndex = findSectionStartIndexWithRegex(firstColumnSheet, "inspection");
    console.log(mixingTableStartIndex, mixingTableEndIndex, extractSectionDataWithIndices(firstColumnSheet, mixingTableStartIndex, mixingTableEndIndex))

    const mixingTableData = trimEmptyArrays(
        extractSectionDataWithIndices(firstColumnSheet, mixingTableStartIndex, mixingTableEndIndex)
    );

    const paddedMixingTableData = mixingTableData.map(row => {
        const paddedRow = [...row];
        while (paddedRow.length < 9) {
            paddedRow.push(null);
        }
        return paddedRow;
    });
    console.log(paddedMixingTableData)

    return paddedMixingTableData;
}

function buildInspectionTable(firstColumnSheet: DataRow[]) {
    const inspectionTableStartIndex = findSectionStartIndexWithRegex(firstColumnSheet, "Inspection");
    const inspectionTableData = trimEmptyArrays(
        extractSectionDataWithIndices(firstColumnSheet, inspectionTableStartIndex, inspectionTableStartIndex + 6)
    );
    const inspection = toInspection(inspectionTableData);
    return inspection;
}

function buildSpecTable(secondColumnSheet: DataRow[]) {
    const coatingSpecTableStartIndex = findSectionStartIndexWithRegex(secondColumnSheet, 'Coating width');
    const coatingSpecTableTitleIndex = coatingSpecTableStartIndex > 0 ? coatingSpecTableStartIndex - 1 : coatingSpecTableStartIndex;
    const maybeEndOfCoatingSpecIndex = findSectionStartIndexWithRegex(secondColumnSheet, 'net amount');
    console.log("maybe", maybeEndOfCoatingSpecIndex);
    let coatingSpecData;
    if (coatingSpecTableTitleIndex == 0) {

        if (maybeEndOfCoatingSpecIndex != -1) {
            const extracted = extractSectionDataWithIndicesno(secondColumnSheet, coatingSpecTableStartIndex, maybeEndOfCoatingSpecIndex);
            coatingSpecData = trimEmptyArrays(
                trimColumnsFromFront(secondColumnSheet, getCellsBeforeKeyword(
                    extracted[coatingSpecTableTitleIndex], 'coating').length));
        } else {
            coatingSpecData = trimEmptyArrays(
                trimColumnsFromFront(secondColumnSheet, getCellsBeforeKeyword(
                    secondColumnSheet[coatingSpecTableTitleIndex], 'coating').length));
        }


    } else {
        if (maybeEndOfCoatingSpecIndex != -1) {
            coatingSpecData = trimEmptyArrays(
                extractSectionDataWithIndicesno(secondColumnSheet, coatingSpecTableStartIndex, maybeEndOfCoatingSpecIndex));
        } else {

            coatingSpecData = trimEmptyArrays(
                extractSectionDataWithIndicesno(secondColumnSheet, coatingSpecTableStartIndex));
        }
    }

    // Function to determine the max row length without trailing empty cells
    const getMaxValidRowLength = (data: any[][]) => {
        return data.reduce((maxLength, row) => {
            // Remove trailing null or empty values
            const trimmedRow = [...row].reverse().filter(cell => cell !== null && cell !== '').reverse();
            return Math.max(maxLength, trimmedRow.length);
        }, 0);
    };
    const maxRowLength = getMaxValidRowLength(coatingSpecData);

    const paddedSpecTableData: any[][] = coatingSpecData.map(row => {
        const paddedRow: any[] = [...row];
        while (paddedRow.length < maxRowLength) {
            paddedRow.push(null);
        }
        return paddedRow;
    });

    return paddedSpecTableData;
}

function buildInstructionNotes(secondColumnSheet: DataRow[]) {
    const coatingSpecTableStartIndex = findSectionStartIndexWithRegex(secondColumnSheet, 'Coating width');
    const coatingSpecTableTitleIndex = coatingSpecTableStartIndex > 0 ? coatingSpecTableStartIndex - 1 : coatingSpecTableStartIndex;

    let noteText = '';
    if (coatingSpecTableTitleIndex == 0) {
        noteText = getCellsBeforeKeyword(secondColumnSheet[coatingSpecTableTitleIndex], 'coating').filter(cell => cell != null).join('\n');
    } else {
        noteText = toExtractNotes(secondColumnSheet, coatingSpecTableTitleIndex);
    }
    console.log("noteText", noteText);
    return noteText;
}

function buildProcessYieldTable(secondColumnSheet: DataRow[]) {
    const processTableStartIndex = findSectionStartIndexWithRegex(secondColumnSheet, 'Process')
    console.log("processTableStartIndex", processTableStartIndex,);
    if (processTableStartIndex != -1) {
        const number = getCellsBeforeKeyword(secondColumnSheet[processTableStartIndex], 'process').length;
        const yieldTable = trimEmptyArrays(trimColumnsFromFront(secondColumnSheet, number));
        console.log("yieldTable", yieldTable,);
        return convertToProcessYield(yieldTable);
    } else {
        return null;
    }
}

function buildMaterialAmountTable(secondColumnSheet: DataRow[]) {

    const materialAmountTableStartIndex = findSectionStartIndexWithRegex(secondColumnSheet, 'Net amount');
    if (materialAmountTableStartIndex != -1) {

        const slicedDataForMaterialTable = extractSectionDataWithIndices(
            secondColumnSheet, materialAmountTableStartIndex, materialAmountTableStartIndex + 6);

        console.log("materialAmountTableStartIndex", slicedDataForMaterialTable,);
        return convertToMaterialAmount(slicedDataForMaterialTable);
    } else {
        return null;
    }
}

/**
 * FilePreviewService Class
 */
export class WorkOrderPreviewService implements WorkOrderPreviewServiceInterface {

    /**
     * Generates HTML preview of the Excel file.
     *
     * @param fileUrl - The URL of the Excel file to preview.
     * @returns A promise that resolves to the HTML string representing the preview.
     */

    async getFilePreviewData(fileUrl: string): Promise<WorkOrderPreviewData> {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) throw new Error(`Failed to fetch the file. Status: ${response.status}`);

            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: "array" });

            checkWorkbookProtection(workbook);
            
            // Identify the target sheet 
            const targetRegex = /(anode|cathode)/i;
            const matchedSheetNames = workbook.SheetNames.filter(name => targetRegex.test(name));
            if (matchedSheetNames.length === 0) throw new Error("No matching sheet found in the Excel file.");

            const workOrderSheetName = this.findWorkOrderSheetName(matchedSheetNames, workbook);
            if (!workOrderSheetName) throw new Error("No work order sheet found.");

            console.log("Work order sheet found:", workOrderSheetName);

            const worksheet = workbook.Sheets[workOrderSheetName];
            const firstColumnSheet = getSheetData(worksheet, { s: { r: 0, c: 1 }, e: { r: 40, c: 9 } });
            const secondColumnSheet = getSheetData(worksheet, { s: { r: 0, c: 11 }, e: { r: 50, c: 17 } });

            const paddedMixingTableData = buildMixingTable(firstColumnSheet);
            const inspection = buildInspectionTable(firstColumnSheet);

            const coatingSpecTable = buildSpecTable(secondColumnSheet);
            const insructionNote = buildInstructionNotes(secondColumnSheet);

            const materialAmountTableStartIndex = findSectionStartIndex(secondColumnSheet, 'Net amount');
            const processTableStartIndex = findSectionStartIndex(secondColumnSheet, 'Process')

            if (materialAmountTableStartIndex == -1 || processTableStartIndex == -1) {
                console.log("Couldn't find a yield table")
            }

            const processYieldData = buildProcessYieldTable(secondColumnSheet)
            const materialsNeeded = buildMaterialAmountTable(secondColumnSheet)

            return {
                noteText: insructionNote,
                inspection,
                processYieldData: processYieldData,
                materialsNeeded: materialsNeeded,
                coatingSpecData: coatingSpecTable,
                mixingTableData: paddedMixingTableData
            };
        } catch (error) {
            console.error("Error generating file preview:", error);
            throw error;
        }
    }

    /**
     * Finds the first matching work order sheet.
     */
    private findWorkOrderSheetName(sheetNames: string[], workbook: any): string | null {
        for (const sheetName of sheetNames) {
            if (getWorkOrderSheetName(sheetName, workbook)) return sheetName;
        }
        return null;
    }



    /**
     * Handles vertical processing of sliced data.
     *
     * @param slicedData - The sliced data to process vertically.
     * @returns Processed yield data.
     */
    private handleVerticalProcessYield(slicedData: DataRow[]): any {
        console.log("Vertical processing");
        return convertToProcessYield(slicedData);
    }

    /**
     * Handles horizontal processing of sliced data.
     *
     * @param slicedData - The sliced data to process horizontally.
     * @returns Processed yield data or null if processing fails.
     */
    private handleHorizontalProcessYield(slicedData: DataRow[]): any | null {
        console.log("Horizontal processing");
        const processColumnIndex = this.findProcessColumnIndex(slicedData[0], 'Process')
        if (processColumnIndex === -1) {
            setError('No "Process" column found in the header.');
            return null;
        }
        const processedData = this.sliceDataFromProcess(slicedData, processColumnIndex);
        return convertToProcessYield(processedData);
    }

    /**
     * Finds the index of the "Process" column in the header row.
     *
     * @param headerRow - The header row.
     * @param processKeyword - The keyword to search for.
     * @returns The index of the "Process" column or -1 if not found.
     */
    private findProcessColumnIndex(headerRow: DataRow, processKeyword: string = 'Process'): number {
        return headerRow.findIndex(cell =>
            typeof cell === 'string' && cell.toLowerCase() === processKeyword.toLowerCase()
        );
    }

    /**
     * Slices each row from the "Process" column onwards.
     *
     * @param data - The data to slice.
     * @param processIndex - The index of the "Process" column.
     * @returns The sliced data.
     */
    private sliceDataFromProcess(data: DataRow[], processIndex: number): DataRow[] {
        return data.map(row => {
            // Ensure the row has enough columns
            if (row.length > processIndex) {
                return row.slice(processIndex);
            }
            // If not, return an empty array or handle as needed
            return [];
        });
    }
}
