import React from 'react';
import { FormulationSortableFields, MaterialSortableFields, WorkOrderSortableFields } from '@twinsketch/topika-model';

// Define a union type for sortable fields
export type CombinedSortableFields = MaterialSortableFields | FormulationSortableFields | WorkOrderSortableFields;

// Define the props type for SortButton
interface SortButtonProps {
    sortBy: CombinedSortableFields;
    setSortBy: React.Dispatch<React.SetStateAction<CombinedSortableFields>>;
    sortDirection: 'asc' | 'desc';
    setSortDirection: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
    onSortChange: (sortField: CombinedSortableFields, sortDirection: 'asc' | 'desc') => void;
}

const SortButton: React.FC<SortButtonProps> = ({ sortBy, setSortBy, sortDirection, setSortDirection, onSortChange }) => {

    const handleSortFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSortField = event.target.value as CombinedSortableFields;
        setSortBy(newSortField);
        onSortChange(newSortField, sortDirection);
    };

    const toggleSortDirection = () => {
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);
        onSortChange(sortBy, newSortDirection);
    };


    return (
        <div className="sort-controls">
            <label htmlFor="sortField">Sort by:</label>
            <select id="sortField" value={sortBy} onChange={handleSortFieldChange}>
                <option value="createdAt">Created At</option>
                <option value="updatedAt">Updated At</option>
                <option value="createdBy">Created By</option>
                <option value="updatedBy">Updated By</option>
                {/* Add other options as needed */}
            </select>
            <button onClick={toggleSortDirection}>
                {sortDirection === 'asc' ? '▲ Ascending' : '▼ Descending'}
            </button>
        </div>
    );
};

export default SortButton;
