import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

interface Tab {
    key?: string;
    label: string;
    content: React.ReactNode;
}

interface TabsProps {
    tabs: Tab[];
    activeKey: string;
    onTabChange: (key: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeKey, onTabChange }) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        // Sync active tab with URL query params
        setSearchParams({ tab: activeKey });
    }, [activeKey, setSearchParams]);


    const handleTabChange = (key: string) => {
        onTabChange(key);
    };

    const renderActiveTabContent = () => {
        const activeTabData = tabs.find((tab) => tab.key === activeKey) || tabs[0];
        return activeTabData?.content || <div>{t("No content available")}</div>;
    };

    return (
        <div className="tabs-container">
            <div className="tabs">
                {tabs.map((tab, index) => {
                    const tabKey = tab.key || `tab-${index}`;
                    return (
                        <button
                            key={tabKey}
                            className={`tab ${activeKey === tabKey ? "active" : ""}`}
                            onClick={() => handleTabChange(tabKey)}
                        >
                            {t(tab.label)}
                        </button>
                    );
                })}
            </div>
            <div className="tab-content">{renderActiveTabContent()}</div>
        </div>
    );
};

export default Tabs;
