import React, { useState } from 'react';
import { Category, UploadedFile, WorkOrder } from '@twinsketch/topika-model';
import FileService from 'src/services/FileService';
import ApiService from 'src/services/ApiService';

const ExcelFileUploader = ({ workOrderId, isWorkOrderCreated, workOrderData, setWorkOrderData, setError }: {
    workOrderId: string;
    isWorkOrderCreated: boolean;
    workOrderData: WorkOrder;
    setWorkOrderData: (data: any) => void;
    setError: (error: string | null) => void;
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [notes, setNotes] = useState<string>('');
    const [fileCategory, setFileCategory] = useState<Category | null>(null);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [uploadComplete, setUploadComplete] = useState(false);

    const handleCategoryChange = (selectedCategory: Category) => {
        setFileCategory(selectedCategory);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;
        if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFile(selectedFile);
            setIsFileSelected(true);
            setUploadComplete(false);
        } else {
            alert('Please select a valid Excel file.');
        }
    };

    const handleFileUpload = async (uploadedFile: UploadedFile) => {
        if (workOrderData && workOrderId) {
            const updatedWorkOrder = {
                ...workOrderData,
                files: [...(workOrderData.files || []), uploadedFile]
            };
            setWorkOrderData(updatedWorkOrder);

            try {
                const updatedWorkOrderResponse = await ApiService.updateWorkOrder(workOrderId, updatedWorkOrder);
                console.log('Updated Work Order:', updatedWorkOrderResponse);
            } catch (error) {
                setError('Failed to update work order. Please try again.');
            }
        }
    };

    const handleUpload = async () => {
        if (!file || !fileCategory) {
            const message = 'Please select a file and category.';
            setUploadError(message);
            alert(message);
            return;
        }

        try {

            const uploadedFile = await FileService.uploadFileWithPresignedUrl(
                file,
                workOrderId,
                notes,
                fileCategory
            );

            // onFileUpload(uploadedFile);
            await handleFileUpload(uploadedFile); // Call the function to update work order data
            setUploadComplete(true);

            // Reset the fields and clear any previous error
            setFile(null);
            setIsFileSelected(false);
            setNotes('');
            setFileCategory(null);
            setUploadError(null);
        } catch (err) {
            console.error('Error uploading file:', err);

            // Try to extract a more detailed error message
            let errorMessage = 'Failed to upload the file.';
            if (err.response) {
                // If using Axios, the error object may contain a response with data
                errorMessage =
                    err.response.data.message || JSON.stringify(err.response.data);
            } else if (err.message) {
                errorMessage = err.message;
            }

            setUploadError(errorMessage);
            alert(errorMessage);
        }
    };

    return (
        <div>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            {file && <p>File Selected: {file.name}</p>}

            {file && (
                <div>
                    <label htmlFor="notes">Notes: </label>
                    <input
                        type="text"
                        id="notes"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Enter notes for this file"
                    />
                </div>
            )}

            {isFileSelected && (
                <div>
                    <p>Select a Category:</p>
                    {Object.values(Category).map((cat) => (
                        <div key={cat}>
                            <input
                                type="radio"
                                id={cat}
                                name="category"
                                value={cat}
                                checked={fileCategory === cat}
                                onChange={() => handleCategoryChange(cat)}
                            />
                            <label htmlFor={cat}>{cat}</label>
                        </div>
                    ))}
                </div>
            )}

            {isFileSelected && fileCategory && (
                <button onClick={handleUpload}>Upload</button>
            )}
            {uploadComplete && (
                <p style={{ color: 'green', marginTop: '10px' }}>
                    Upload successful! Please refresh the page to see the uploaded file.
                </p>
            )}
        </div>
    );
};

export default ExcelFileUploader;
