
export type OverviewData = {
    date: string | null;
    activeMaterial: string | null;
    activeMaterialLotNo: string | null;
    projectNo: string | null;
    수주번호: string | null;
    poNo: string | null;
    indoorTempHumidity: string | null;
    작업자: string | null;
    mixer: string | null;
};

/**
 * A list of known headings to prevent mis-parsing them as data.
 * You can add or remove items depending on your forms.
 */
const HEADERS = [
    "작업일자",
    "활물질",
    "lot no.",
    "lotno.:",    // catch variants like (Lot No.:
    "project no.",
    "수주번호",
    "po no",
    "실내 온/습도",
    "작업자",
    "mixer"
];

// A small helper to normalize strings for comparison.
const normalize = (val: string) =>
    val.replace(/\s+/g, "").replace(/\./g, "").replace(/-/g, "").toLowerCase();

/**
 * Returns true if 'value' is recognized as one of our known headings.
 */
function isKnownHeading(value: string): boolean {
    const normVal = normalize(value);
    return HEADERS.some(h => normVal.includes(normalize(h)));
}


export function parseOverviewData(nestedData: Array<Array<string | number | null>>): OverviewData {
    // Flatten the nested array into a single array.
    const flatData = nestedData.flat();

    // Helper to find the index of a keyword (partial match is okay).
    // For instance: findKeyIndex(["작업일자", "작업 일 자"]) -> index in flatData
    const findKeyIndex = (keys: string[]): number => {
        return flatData.findIndex(item => {
            if (typeof item !== "string") return false;
            const normItem = normalize(item);
            return keys.some(k => normItem.includes(normalize(k)));
        });
    };

    // Helper to get the next non-null, non-empty string after a given index.
    // If the next item we find is also a heading, we skip it and return null.
    const getNextString = (startIndex: number): string | null => {
        for (let i = startIndex + 1; i < flatData.length; i++) {
            const nextVal = flatData[i];
            if (nextVal !== null && nextVal !== undefined && `${nextVal}`.trim() !== "") {
                // If the next string is itself a heading, that means there's no data for this field.
                if (typeof nextVal === "string" && isKnownHeading(nextVal)) {
                    return null;
                }
                return String(nextVal).trim();
            }
        }
        return null;
    };

    // Initialize result object
    let result: OverviewData = {
        date: null,
        activeMaterial: null,
        activeMaterialLotNo: null,
        projectNo: null,
        수주번호: null,
        poNo: null,
        indoorTempHumidity: null,
        작업자: null,
        mixer: null,
    };

    // --- 1) 작업일자 ---
    {
        const dateIndex = findKeyIndex(["작업일자", "작업 일 자"]);
        if (dateIndex !== -1) {
            const rawDate = getNextString(dateIndex);
            if (rawDate) {
                // Clean up the date string (e.g. "2024. 06 . 24 ." -> "2024.06.24")
                const cleanedDate = rawDate
                    .replace(/\s+/g, "")
                    .replace(/\.{2,}/g, ".")
                    .replace(/\.$/, "");
                result.date = cleanedDate;
            }
        }
    }

    // --- 2) 활물질 ---
    {
        const activeMaterialIndex = findKeyIndex(["활물질", "활 물 질"]);
        if (activeMaterialIndex !== -1) {
            result.activeMaterial = getNextString(activeMaterialIndex);
        }
    }

    // --- 3) (Lot No.:) ---
    {
        const lotIndex = findKeyIndex(["(lotno.:", "(lot no.:", "lot no."]);
        if (lotIndex !== -1) {
            const lotCandidate = getNextString(lotIndex);
            if (lotCandidate) {
                // Example cleanup if there's a trailing ")"
                result.activeMaterialLotNo = lotCandidate.replace(/\)$/, "");
            }
        }
    }

    // --- 4) Project No ---
    {
        const projectIndex = findKeyIndex(["projectno", "project no"]);
        if (projectIndex !== -1) {
            result.projectNo = getNextString(projectIndex);
        }
    }

    // --- 5) 수주번호 ---
    {
        const soIndex = findKeyIndex(["수주번호"]);
        if (soIndex !== -1) {
            result.수주번호 = getNextString(soIndex);
        }
    }

    // --- 6) PO No ---
    {
        const poIndex = findKeyIndex(["pono", "po no"]);
        if (poIndex !== -1) {
            result.poNo = getNextString(poIndex);
        }
    }

    // --- 7) 실내 온/습도 ---
    {
        const indoorIndex = findKeyIndex(["실내온/습도", "실내 온/습도"]);
        if (indoorIndex !== -1) {
            // We'll try to form something like "26℃ / 7.8%"
            // by looking at the next ~10 items.
            let temperature: string | null = null;
            let humidity: string | null = null;
            const slice = flatData.slice(indoorIndex + 1, indoorIndex + 11);

            let foundTemp = false;
            for (const piece of slice) {
                if (piece == null) continue;

                // If we haven't found temp yet, take the first numeric as temperature
                if (!foundTemp && typeof piece === "number") {
                    temperature = String(piece);
                    foundTemp = true;
                    continue;
                }

                // Once we have temp, the next numeric is humidity
                if (foundTemp && humidity == null && typeof piece === "number") {
                    humidity = String(piece);
                    break;
                }
            }

            if (temperature && humidity) {
                result.indoorTempHumidity = `${temperature}℃ / ${humidity}%`;
            } else if (temperature) {
                result.indoorTempHumidity = `${temperature}℃`;
            }
        }
    }

    // --- 8) 작업자 ---
    {
        const operatorIndex = findKeyIndex(["작업자", "작 업 자"]);
        if (operatorIndex !== -1) {
            result.작업자 = getNextString(operatorIndex);
        }
    }

    // --- 9) Mixer ---
    {
        const mixerIndex = findKeyIndex(["mixer"]);
        if (mixerIndex !== -1) {
            result.mixer = getNextString(mixerIndex);
        }
    }

    return result;
}
