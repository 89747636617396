import React, { useState } from 'react';
import { MixingReportPreviewData } from './MixingReportPreviewService';
import { ParsedConiditionData } from './MixingConditionHelper';
import { CompositionData } from './MixingFormulationHelper';
import { InspectionDataResult } from './MixingInspectionHelper';
import { OverviewData } from './MixingOverviewHelper';
import { ProcessData } from './MixingProcessHelper';
import { MixingWork, WorkOrder } from '@twinsketch/topika-model';
import ApiService from 'src/services/ApiService';

// Utility function to format cell values
const formatCellValue = (cell: any) => {
    if (cell === null || cell === undefined || cell === '') {
        return '-';
    }
    if (typeof cell === 'number') {
        return cell.toLocaleString(); // Format numbers with commas
    }
    return cell.toString();
};

// ==================== Overview Table ====================

interface OverviewTableProps {
    overviewData: OverviewData;
}

export const OverviewTable: React.FC<OverviewTableProps> = ({ overviewData }) => {
    const {
        date,
        activeMaterial,
        activeMaterialLotNo,
        projectNo,
        수주번호,
        poNo,
        indoorTempHumidity,
        작업자,
        mixer
    } = overviewData;

    return (
        <div className="table-section">
            <h2 style={{ fontSize: '16px' }}>Mixing Overview</h2>
            <table className="mixing-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #ddd' }}>Field</th>
                        <th style={{ border: '1px solid #ddd' }}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>Date</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(date)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>Active Material</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(activeMaterial)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>Active Material Lot No.</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(activeMaterialLotNo)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>Project No.</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(projectNo)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>수주번호</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(수주번호)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>PO No.</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(poNo)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>Indoor Temp/Humidity</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(indoorTempHumidity)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>작업자</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(작업자)}</td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>Mixer</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(mixer)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

// ==================== Mixing Formulation Table ====================
interface MixingFormulationTableProps {
    compositionData: CompositionData[];
}

export const MixingFormulationTable: React.FC<MixingFormulationTableProps> = ({ compositionData }) => {
    return (
        <div className="table-section">
            <h2 style={{ fontSize: '16px' }}>Mixing Formulation</h2>
            <table className="mixing-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #ddd' }}>Category</th>
                        <th style={{ border: '1px solid #ddd' }}>Ratio</th>
                        <th style={{ border: '1px solid #ddd' }}>Name</th>
                        <th style={{ border: '1px solid #ddd' }}>Weight</th>
                    </tr>
                </thead>
                <tbody>
                    {compositionData.map((item, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.category)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.ratio)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.name)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.weight)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// ==================== Mixing Condition Table ====================

interface MixingConditionTableProps {
    conditionData: ParsedConiditionData;
}

export const MixingConditionTable: React.FC<MixingConditionTableProps> = ({ conditionData }) => {
    const { viscosity, temp, filteringMesh, vacuum } = conditionData;

    return (
        <div className="table-section">
            <h2 style={{ fontSize: '16px' }}>Mixing Condition</h2>
            <table className="mixing-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #ddd' }}>Viscosity</th>
                        <th style={{ border: '1px solid #ddd' }}>Temp (℃)</th>
                        <th style={{ border: '1px solid #ddd' }}>Filtering Mesh</th>
                        <th style={{ border: '1px solid #ddd' }}>Vacuum</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(viscosity)}</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(temp)}</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(filteringMesh)}</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(vacuum)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

// ==================== Mixing Process Table ====================
interface MixingProcessTableProps {
    processData: ProcessData[];
}

export const MixingProcessTable: React.FC<MixingProcessTableProps> = ({ processData }) => {
    return (
        <div className="table-section">
            <h2 style={{ fontSize: '16px' }}>Mixing Process</h2>
            <table className="mixing-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #ddd' }}>Sequence</th>
                        <th style={{ border: '1px solid #ddd' }}>Material</th>
                        <th style={{ border: '1px solid #ddd' }}>Weight (kg)</th>
                        <th style={{ border: '1px solid #ddd' }}>Mixing RPM (Low)</th>
                        <th style={{ border: '1px solid #ddd' }}>Mixing RPM (High)</th>
                        <th style={{ border: '1px solid #ddd' }}>Time (min)</th>
                        <th style={{ border: '1px solid #ddd' }}>CPS</th>
                        <th style={{ border: '1px solid #ddd' }}>Note</th>
                    </tr>
                </thead>
                <tbody>
                    {processData.map((item, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.sequence)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.material)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.weight)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.mixingRpmLow)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.mixingRpmHigh)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.time)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.cps)}</td>
                            <td style={{ border: '1px solid #ddd' }}>{formatCellValue(item.note)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// ==================== Mixing Inspection Table ====================
interface MixingInspectionTableProps {
    inspectionData: InspectionDataResult;
}

export const MixingInspectionTable: React.FC<MixingInspectionTableProps> = ({ inspectionData }) => {
    const {
        grindtest,
        viscosity,
        solidContentExpected,
        solidCntentActula // note the original naming
    } = inspectionData;

    return (
        <div className="table-section">
            <h2 style={{ fontSize: '16px' }}>Mixing Inspection</h2>
            <table className="mixing-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #ddd' }}>Grind Test</th>
                        <th style={{ border: '1px solid #ddd' }}>Viscosity (cps)</th>
                        <th style={{ border: '1px solid #ddd' }}>Solid Content (Expected)</th>
                        <th style={{ border: '1px solid #ddd' }}>Solid Content (Actual)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(grindtest)}</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(viscosity)}</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(solidContentExpected)}</td>
                        <td style={{ border: '1px solid #ddd' }}>{formatCellValue(solidCntentActula)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

// ==================== Mixing Notes Component (optional table or list) ====================
interface MixingNotesProps {
    notes: string[];
}

export const MixingNotes: React.FC<MixingNotesProps> = ({ notes }) => {
    if (!notes || notes.length === 0) {
        return null;
    }
    return (
        <div className="table-section">
            <h2 style={{ fontSize: '16px' }}>Notes</h2>
            <ul style={{ fontSize: '12px', lineHeight: '1.0' }}>
                {notes.map((note, index) => (
                    <li key={index}>{formatCellValue(note)}</li>
                ))}
            </ul>
        </div>
    );
};

// ==================== Example Usage: Combining all in a single component ====================

interface MixingReportPreviewProps {
    previewData: MixingReportPreviewData;
}

export const MixingReportPreview: React.FC<MixingReportPreviewProps> = ({ previewData }) => {
    return (
        <div>
            <OverviewTable overviewData={previewData.mixingOverview} />
            <MixingFormulationTable compositionData={previewData.mixingFormulation} />
            <MixingConditionTable conditionData={previewData.mixingCondition} />
            <MixingProcessTable processData={previewData.mixingProcess} />
            <MixingInspectionTable inspectionData={previewData.mixingInspection} />
            <MixingNotes notes={previewData.notes} />
        </div>
    );
};

// Helper function to extract number from a string (removes non-numeric characters)
export const extractNumber = (value: string): number => {
    const num = parseFloat(value.replace(/[^\d.]/g, ""));
    return isNaN(num) ? 0 : num; // Default to 0 if NaN
};

// Helper function to convert date to YYYY-MM-DD format
export const formatDateToYYYYMMDD = (dateString: string): string => {
    // Extract the YYYY.MM.DD part using regex
    const match = dateString.match(/^(\d{4})\.(\d{2})\.(\d{2})/);

    if (!match) return ""; // Return empty string if format is incorrect

    const [, year, month, day] = match; // Destructure extracted values
    return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
};

const splitAndSortNames = (names: string): string[] => {
    if (!names || names.trim().length === 0) return [];

    return names
        .split(',')
        .map(name => name.trim()) // Trim whitespace
        .filter(name => name.length > 0) // Remove any empty strings
        .sort((a, b) => a.localeCompare(b, 'ko-KR')); // Sort alphabetically (Korean)
};

export const MixingReportPreviewModal: React.FC<{
    data: MixingReportPreviewData;
    fileId: string;
    workOrderId: string;
    workOrderData: WorkOrder;
    onClose: () => void
}> = ({ data, fileId, workOrderId, workOrderData, onClose }) => {
    const [isExporting, setIsExporting] = useState(false);
    const handleExportToWorkOrder = async () => {
        setIsExporting(true);

        try {
            // Extract necessary data for mixing work
            const workedOn = formatDateToYYYYMMDD(data.mixingOverview.date);
            const workedBy = splitAndSortNames(data.mixingOverview.작업자);
            const tankCap = extractNumber(data.mixingOverview.mixer);
            const mixingData: MixingWork = {
                workedOn: workedOn,
                workedBy: workedBy,
                tankCapacity: tankCap,
                grindTestResult: formatCellValue(data.mixingInspection.grindtest),
                measuredViscosity: formatCellValue(data.mixingInspection.viscosity),
                calculatedSolidContent: formatCellValue(data.mixingInspection.solidContentExpected),
                measuredsolidContent: formatCellValue(data.mixingInspection.solidCntentActula),
                originFileId: fileId,
            };

            console.log(data.mixingOverview.작업자, workedBy, splitAndSortNames(data.mixingOverview.작업자))
            // Ensure we have existing work order data and a valid work order ID
            if (!workOrderData || !workOrderId) {
                throw new Error("Work order data or ID is missing.");
            }

            let updatedMixingWork;
            if (workOrderData.mixingWork) {
                const existingMixingWorkIndex = workOrderData.mixingWork.findIndex(mw => mw.originFileId && mw.originFileId === fileId);
                if (existingMixingWorkIndex !== -1) {
                    // Update the existing mixingWork entry
                    updatedMixingWork = [...workOrderData.mixingWork];
                    updatedMixingWork[existingMixingWorkIndex] = {
                        ...updatedMixingWork[existingMixingWorkIndex],
                        ...mixingData  // Merge new data with existing data
                    };
                } else {
                    // Append a new mixingWork entry
                    updatedMixingWork = [...workOrderData.mixingWork, mixingData];
                }
            }

            // Prepare the updated work order
            const updatedWorkOrder = {
                ...workOrderData,
                mixingWork: updatedMixingWork
            };
            // Call the API to update the work order
            const updatedWorkOrderResponse = await ApiService.updateWorkOrder(workOrderId, updatedWorkOrder);
            console.log("Updated Work Order:", updatedWorkOrderResponse);
            alert("Successfully exported to work order!");
        } catch (error) {
            console.error("Error exporting to work order:", error);
        } finally {
            setIsExporting(false);
        }
    };


    return (
        <div>
            <div className="modal" style={{ display: 'block', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
                <div className="modal-content" style={{ backgroundColor: 'white', padding: '20px', margin: '50px auto', width: '80%', maxHeight: '80%', overflowY: 'auto', position: 'relative' }}>

                    {/* Button Container at the Top Right */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', position: 'absolute', top: '10px', right: '10px' }}>
                        <button onClick={onClose} style={{ padding: '8px 12px', backgroundColor: '#f44336', color: 'white', border: 'none', cursor: 'pointer' }}>
                            Close
                        </button>
                        <button onClick={handleExportToWorkOrder} disabled={isExporting} style={{ padding: '8px 12px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}>
                            {isExporting ? 'Exporting...' : 'Export to Work Order'}
                        </button>
                    </div>

                    {/* Main Content */}
                    <div style={{ marginTop: '40px' }}>
                        <MixingReportPreview previewData={data} />
                    </div>

                </div>
            </div>
        </div>

    );
};
